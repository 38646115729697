import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import "react-tabs/style/react-tabs.css";

export default function HomeTabs() {
  return (
    <div className="home-tabs">
      <div className="siteWidth">
        <Tabs>
          <TabList>
            <Tab>Mobile Application</Tab>
            <Tab>Website Design</Tab>
            <Tab>Digital Marketing</Tab>
          </TabList>
          <TabPanel>
            <div className="tab-content">
              <div className="tab-content-top">
                <p>
                  Our work has placed us among the top mobile app development
                  companies in the INDIA. With our wide range of world-class
                  enterprise mobility solutions, we are expert in providing best
                  mobile app development services to write the success story of
                  your business.
                </p>
                <p>
                  When the experience of our mobile app developers blends with
                  the creative art of developing a mobile application, we thrive
                  to provide your business with the best mobile experience.
                  Because the onus of your success lies in our credibility to
                  deliver top-notch mobile app solutions.
                </p>
              </div>
              <div className="tab-content-bottom">
                <h3>
                  Native Apps / Cross-Platform Apps / Hybrid Apps / Progressive
                  web Apps
                </h3>
                <p>Native apps offer the best runtime performance.</p>
                <p>Single code base for multiple platforms.</p>
                <p>Codebase is shared between web and mobile apps.</p>
                <p>Apps run on the web, as well as mobile.</p>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="tab-content">
              <div className="tab-content-top">
                <p>
                  Repeated business model doesn’t work these days, unless you
                  have an internet wing to tap on the power of online customers.
                  Welcome to Techsell India we offer a range of web development
                  services, designed to cater across all niches and industries.
                  We are your one stop for all kinds of web designing needs,
                  with services like consulting, designing, maintenance and
                  marketing of websites.
                </p>
                <p>
                  Over the years, we have grown as a web designing firm,
                  offering the most relevant services to customers. No matter
                  whether you are new to the online business world or have a
                  website that isn’t generating revenue as you expected, we are
                  here to help. From designing and developing websites from the
                  scratch to redesigning and remodeling an existing site, we
                  take it all.
                </p>
              </div>
              <div className="tab-content-bottom">
                <h3>We are pros at all Tech &amp; CMS</h3>
                <ul>
                  <li>HTML</li>
                  <li>Java script</li>
                  <li>WordPress</li>
                  <li>PHP</li>
                  <li>Core PHP</li>
                  <li>AngulerJs</li>
                  <li>NodeJS</li>
                  <li>General websites</li>
                  <li>Static websites</li>
                  <li>Dynamic websites</li>
                  <li>Business portals</li>
                  <li>Ecommerce sites</li>
                  <li>jQuery</li>
                  <li>SQL</li>
                </ul>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="tab-content">
              <div className="tab-content-top">
                <p>
                  <b>
                    Connect with your target audience, using our effective
                    native advertising solutions for both desktop and mobile
                    devices.Create Campaign
                  </b>
                  <br />
                  When it comes to native advertising and content distribution,
                  plista offers advertisers various effective and compelling ad
                  options that are suitable for both brand and performance
                  marketing. Reach your marketing goals easily and efficiently:
                </p>
                <p>
                  <b>
                    Great content for your readers. Sustainable revenue and
                    traffic for you.
                  </b>
                  <br />
                  By integrating our proprietary Recommendation Technology at no
                  cost, you can offer content and advertising that matches the
                  personal interests of every visitor to your website or app.
                  Connect with your users and benefit from plista across the
                  board:
                </p>
              </div>
              <div className="tab-content-bottom">
                <h3>We are pros at all Tech &amp; CMS</h3>
                <ul>
                  <li> Increase website stickiness</li>
                  <li> Generate sustainable revenue</li>
                  <li>Drive on-site and cross-site traffic </li>
                  <li>
                    Monitor, analyze and optimize your website performance in
                    real time
                  </li>
                  <li>Increase brand awareness</li>
                  <li>Boost user engagement &amp; brand interaction </li>
                  <li>Generate leads &amp; conversions</li>
                  <li>Strengthen customer loyalty &amp; brand trust </li>
                </ul>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
}
