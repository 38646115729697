import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export default function EmailUsBtn() {
  return (
    <div className="email-us-btn">
      <a href="mailto:info@techsellindia.com">
        <FontAwesomeIcon icon={faEnvelope} /> Email Us: info@techsellindia.com
      </a>
    </div>
  );
}
