import { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAppStoreIos, faFirefox, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faChartLine,
  faPaintRoller,
  faShareAlt,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

const mobileApp = <FontAwesomeIcon icon={faAppStoreIos} />;
const webapp = <FontAwesomeIcon icon={faFirefox} />;
const digital = <FontAwesomeIcon icon={faChartLine} />;
const website = <FontAwesomeIcon icon={faPaintRoller} />;
const sharepoint = <FontAwesomeIcon icon={faShareAlt} />;
const whatsapp = <FontAwesomeIcon icon={faWhatsapp} />;

export default function Navigaton() {
  const [isSubmenu, setSubmenu] = useState("false");
  const showSubMenu = () => {
    setSubmenu(!isSubmenu);
  };

  return (
    <nav className="main-navigation">
      <ul>
        <li className="main-item">
          <Link to="/">Home</Link>
        </li>
        <li className="main-item">
          <Link to="/about">About Us</Link>
        </li>
        <li className="main-item has-child">
          <a href="/">Service</a>
          <span className="sub-icon" onClick={showSubMenu}>
            <FontAwesomeIcon icon={faChevronDown} />
          </span>
          <ul
            className={
              isSubmenu ? "sub-menu hide-sub-menu" : "sub-menu show-sub-menu"
            }
          >
            <li>
              <Link to="/mobile_app_development">
                Mobile App Development{" "}
                <span className="n_icon">{mobileApp}</span>
              </Link>
            </li>
            <li>
              <Link to="/web_development">
                Web Development <span className="n_icon">{webapp}</span>
              </Link>
            </li>
            <li>
              <Link to="/digital_marketing">
                Digital Marketing <span className="n_icon">{digital}</span>
              </Link>
            </li>
            <li>
              <Link to="/website_designing">
                Website Designing <span className="n_icon">{website}</span>
              </Link>
            </li>
            <li>
              <Link to="/sharepoint">
                SharePoint <span className="n_icon">{sharepoint}</span>
              </Link>
            </li>
          </ul>
        </li>
        <li className="main-item">
          <Link to="/portfolio">Portfolio</Link>
        </li>
        <li className="main-item">
          <Link to="/contact">Contact Us</Link>
        </li>
        <li className="price_btn">
          <a href="https://wa.me/91204127124" className="">
            {whatsapp} 1204127124
          </a>
        </li>
        <li className="price_btn">
          <Link to="/contact" className="">
            Get A Free Quote
          </Link>
        </li>
      </ul>
    </nav>
  );
}
