export default function AboutMission() {
  return (
    <div className="about-mission">
      <div className="siteWidth">
        <h2>Mission, Vision and Values</h2>
        <h3>Mission</h3>
        <p>
          We endeavor to come up with ample insights and result-driven aspects
          by offering an experienced and skilled team to our clients. To provide
          a dedicated team to develop futuristic web and mobile software
          solutions for businesses ranging from startups to enterprises with
          satisfaction.
        </p>

        <h3>Vision</h3>
        <p>
          Vision We envision leading the outsourcing and offshoring solution
          providing market in India and provide teams to businesses across the
          globe.
        </p>
        <h3>Values</h3>
        <p>
          Our values add up for constant growth and success in serving the
          clients. We expand on accomplishing performance, technologies, project
          management tools, and focus on talented resources for serving the
          clients.
        </p>
      </div>
    </div>
  );
}
