import React from "react";
import DocumentMeta from "react-document-meta";

export default function Covid19() {
  const meta = {
    title: "Covid 19",
    description: "I am a description, and I can create multiple tags",
    canonical: "http://example.com/path/to/page",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "react,meta,document,html,tags",
      },
    },
  };

  return (
    <DocumentMeta {...meta}>
      <div className="inner-page mobile-app-page">
        <div className="mobile-app-content">
          <div className="siteWidth">
            <div className="text-center">
              <h1>
                Employee Well-being and Business Continuity as we Deal with
                COVID-19
              </h1>
            </div>
            <h3>Message To Our Clients - By "Aniket Singh"</h3>
            <p>
              With the steps we are all taking to address the COVID-19
              situation, I wanted to share our approach with you. Like you, we
              too are focusing on the safety of our employees , the communities
              we live and work in, and ensuring we diligently meet all our
              service commitments. Please take care and stay safe.
            </p>

            <h3>
              Employee Well-being and Everything you wanted to know about how we
              are making every effort to manage the situation Continuity as we
              Deal with COVID-19
            </h3>

            <h4>1. Will client services be impacted due to COVID-19?</h4>
            <p>
              Techsell India is taking multiple steps to ensure that, even as we
              deal with this unprecedented situation, we deliver on our key
              client deliverables, while following local government advisories
              and directives, and ensuring the safety of our employees and
              communities.
            </p>

            <h4>2. How is Techsell India keeping its employees safe?</h4>
            <p>
              Prioritizing the safety of our employees, Techsell India has
              advised all its employees to work from home , and we are taking
              all reasonable steps to ensure continued service delivery to our
              clients. We are also following local government directives with
              regard to our workplaces in all the States we operate in.
            </p>

            <h4>
              3. Has Techsell India enabled work from home for all employees?
            </h4>
            <p>
              Techsell India has advised all its employees to work from home
              wherever possible, and we are taking all reasonable steps to
              ensure continued service delivery to our clients.
            </p>
          </div>
        </div>
      </div>
    </DocumentMeta>
  );
}
