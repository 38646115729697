import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faCubes,
  faLightbulb,
  faMobile,
} from "@fortawesome/free-solid-svg-icons";

export default function AboutWhatWeOffer() {
  return (
    <div className="about-what-we-offer">
      <div className="siteWidth">
        <div className="about-whoweare-left">
          <h2>What We Offer</h2>
          <p>
            We offer a wide range of IT solutions ranging from Software
            development, Website Development, e-commerce online solution, ERP
            Management. Our core expertise is to develop cloud based custom apps
            using the latest technologies.
          </p>
        </div>
        <div className="about-whoweare-right">
          <div className="awr-box">
            <div className="awr-box-in">
              <div className="awr-box-icon">
                <FontAwesomeIcon icon={faLightbulb} />
              </div>
              <div className="awr-box-text">
                <h3>WEB DESIGN</h3>
                <p>
                  We help you define your Identity in online world's &amp;
                  develop a realistic strategy with you.
                </p>
              </div>
            </div>
          </div>

          <div className="awr-box">
            <div className="awr-box-in">
              <div className="awr-box-icon">
                <FontAwesomeIcon icon={faMobile} />
              </div>
              <div className="awr-box-text">
                <h3>APP DEVELOPMENT</h3>
                <p>
                  Versatile business apps, developed with precision, are sure
                  shot business tools .
                </p>
              </div>
            </div>
          </div>

          <div className="awr-box">
            <div className="awr-box-in">
              <div className="awr-box-icon">
                <FontAwesomeIcon icon={faCubes} />
              </div>
              <div className="awr-box-text">
                <h3>SOFTWARE ERP</h3>
                <p>
                  Making with experience of designing amazing ERP and portals .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
