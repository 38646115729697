import {
  WhatsApp,
  ArrowRight
} from "@mui/icons-material";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import React, { useState } from "react";
import DocumentMeta from "react-document-meta";
import topImage1 from "../../assets/images/business-solution/fe-1-1.png";
import topImage2 from "../../assets/images/business-solution/fe-1-2.png";
import topImage3 from "../../assets/images/business-solution/fe-1-3.png";
import shopify from "../../assets/images/shopify.webp";
import woocommerce from "../../assets/images/woocommerce.webp";
import wordpress from "../../assets/images/wordpress.webp";
import axios from "axios"
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

export default function BusinessSolution() {

  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [btn, setBtn] = useState("GET QUOTE");
  const onSubmit = (data) => {
    setBtn("Please wait...")
    axios.post("https://techsellindia.com/admin/api/contact_us", data).then((r) => {
      if (r.data.rcode == 200) {
        setBtn("ENQUIRY SENT")
        setTimeout(() => {
          setBtn("GET QUOTE")
        }, 2000);
      } else {
        setBtn("Something went wrong!")
      }
    })
  };

  const [showinc1, setShowinc1] = useState()
  const [showinc2, setShowinc2] = useState()
  const [showinc3, setShowinc3] = useState()
  const [showinc4, setShowinc4] = useState()

  const showincfun = (e) => {
    if (e == "show1") {
      if (showinc1 == "showinc") {
        setShowinc1("")
      } else {
        setShowinc1("showinc")
      }
    }

    if (e == "show2") {
      if (showinc2 == "showinc") {
        setShowinc2("")
      } else {
        setShowinc2("showinc")
      }
    }
    if (e == "show3") {
      if (showinc3 == "showinc") {
        setShowinc3("")
      } else {
        setShowinc3("showinc")
      }
    }
    if (e == "show4") {
      if (showinc4 == "showinc") {
        setShowinc4("")
      } else {
        setShowinc4("showinc")
      }
    }
  }

  const meta = {
    title: "Business Solutions Service",
    description: "I am a description, and I can create multiple tags",
    canonical: "http://example.com/path/to/page",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "react,meta,document,html,tags",
      },
    },
  };

  const [expandedPanel, setExpandedPanel] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    console.log({ event, isExpanded });
    setExpandedPanel(isExpanded ? panel : false);
  };

  return (
    <DocumentMeta {...meta}>
      <div className="business-solutions pt-4">
        <div className="business-solutions-content">
          <div className="hero-image">
            <div className="siteWidth">
              <div className="hero-image-box">
                <div className="hero-image-box-inner">
                  <div className="hero-image-box-left">
                    <h1>
                      Business Management <span>WE HAVE TOP IT EXPERT</span>
                    </h1>
                    <h3>SERVICE &amp; SOLUTION 2022</h3>
                    <p>
                      Professionally repurpose intuitive total linkage after
                      timely mindshare.
                    </p>
                    <p>
                      Credibly coordinate reliable collaboration and idea-sharing
                      after turnkey catalysts for change.
                    </p>
                    <Link to="/contact" className="btn-main inline-block mt-5">
                      Contact Us
                    </Link>
                  </div>
                  <div className="hero-image-right">
                    <h2>Get a Free Quote</h2>
                    <div className="hero-image-right-form">
                      <form action="" onSubmit={handleSubmit(onSubmit)}>
                        <input type="hidden" className="form-control" {...register("page_name")} defaultValue="Business Solution" />
                        <input type="hidden" className="form-control" {...register("type")} defaultValue="Query" />
                        <input type="text" placeholder="Name" className="form-control" {...register("name", { required: true })} />
                        <input type="number" placeholder="Phone" className="form-control" {...register("phone", { required: true })} />
                        <input type="email" placeholder="Email" className="form-control" {...register("email", { required: true, pattern: /^\S+@\S+$/i })} />
                        <input type="text" placeholder="What is your project about?" className="form-control" {...register("message", { required: true })} />
                        <button>{btn}</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="business-development-top">
            <div className="siteWidth">
              <div className="business-development-top-boxes">
                <div className="box-inner">
                  <img src={topImage1} alt="" />
                  <h2>Development Services</h2>
                  <p>
                    Completely implement via highly efficient process
                    improvements. engage high value before progressive data.
                  </p>
                </div>

                <div className="box-inner">
                  <img src={topImage2} alt="" />
                  <h2>Marketing Services</h2>
                  <p>
                    Completely implement via highly efficient process
                    improvements. engage high value before progressive data.
                  </p>
                </div>

                <div className="box-inner">
                  <img src={topImage3} alt="" />
                  <h2>Consulting Services</h2>
                  <p>
                    Completely implement via highly efficient process
                    improvements. engage high value before progressive data.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="business-development-package-box-large">
            <div className="business-development-box">
              <h2 className="pb-3">Choose Best Package For Your Business</h2>
              <div className="bdpbl-in">
                <div className="bdpbl-box">
                  <div className="bdpbl-box-header">
                    <h3>Gold Website Package</h3>
                    <p>E-Commerce Website</p>
                    <div className="bdpbl-box-header-price">
                      <p><span>₹</span>44,999</p>
                      <span className="usdprice">($563)</span>
                    </div>
                  </div>

                  <div className="bdpbl-box-body">
                    <ul>
                      <li>E-Commerce Website </li>
                      <li>Customized Design </li>
                      <li>Free Hosting 1 Year</li>
                      <li>Free Domain 1 Year </li>
                      <li>SEO Ready Website </li>
                      <li>Business E-Mail 10 </li>
                      <li>SSL Certificate</li>
                      <li>Control Panel </li>
                    </ul>
                  </div>

                  <div className="bdpbl-box-acc">
                    <h4 onClick={() => showincfun("show1")}><span><ArrowRight /></span> View More Inclusions</h4>
                    <ul className={showinc1}>
                      <li>Responsive Design </li>
                      <li>Content 1000 words </li>
                      <li>Social Media Integration </li>
                      <li>Google Analytics </li>
                      <li>Google Maps Integration </li>
                      <li>Advanced Live Chat Bot</li>
                      <li>Whatsapp Integration </li>
                      <li>High Speed Guarantee </li>
                      <li>Search Console Integration </li>
                      <li>Payment Gateway Integrate</li>
                    </ul>
                  </div>

                  <div className="bdpbl-box-footer">
                    <a href="https://wa.me/91204127124" target="_blank"><span className="icon"><WhatsApp /> 1204127124 </span></a>
                  </div>
                </div>

                <div className="bdpbl-box">
                  <div className="bdpbl-box-header">
                    <h3>Gold+ Website Package</h3>
                    <p>Advanced E-Commerce Website</p>
                    <div className="bdpbl-box-header-price">
                      <p><span>₹</span>64,999</p>
                      <span className="usdprice">($814)</span>
                    </div>
                  </div>

                  <div className="bdpbl-box-body">
                    <ul>
                      <li>E-Commerce Website</li>
                      <li>Wallet and Referral Integration</li>
                      <li>Abandoned Checkout</li>
                      <li>Customized Design</li>
                      <li>Free Hosting 1 Year</li>
                      <li>Free Domain 1 Year</li>
                      <li>SEO Ready Website</li>
                      <li>Business E-Mail 15</li>
                    </ul>
                  </div>

                  <div className="bdpbl-box-acc">
                    <h4 onClick={() => showincfun("show2")}><span><ArrowRight /></span> View More Inclusions</h4>
                    <ul className={showinc2}>
                      <li>SSL Certificate</li>
                      <li>Control Panel</li>
                      <li>Responsive Design</li>
                      <li>Content 2000 words</li>
                      <li>Social Media Integration</li>
                      <li>Google Analytics</li>
                      <li>Google Maps Integration</li>
                      <li>Advanced Live Chat Bot</li>
                      <li>Whatsapp Integration</li>
                      <li>Conversion Tracking</li>
                      <li>Pixel Integration</li>
                      <li>High Speed Guarantee</li>
                      <li>Search Console Integration</li>
                      <li>Social Login Option</li>
                      <li>Standard Ecommerce Policies</li>
                      <li>Payment Gateway Integration</li>
                    </ul>
                  </div>

                  <div className="bdpbl-box-footer">
                    <a href="https://wa.me/91204127124" target="_blank"><span className="icon"><WhatsApp /> 1204127124</span></a>
                  </div>
                </div>

                <div className="bdpbl-box">
                  <div className="bdpbl-box-header">
                    <h3>Platinum Website Package</h3>
                    <p>Advanced E-Commerce Website + Android App</p>
                    <div className="bdpbl-box-header-price">
                      <p><span>₹</span>84,999</p>
                      <span className="usdprice">($1064)</span>
                    </div>
                  </div>

                  <div className="bdpbl-box-body">
                    <ul>
                      <li>E-Commerce Website</li>
                      <li>Wallet and Referral Integration</li>
                      <li>Abandoned Checkout</li>
                      <li>Android Hybrid App</li>
                      <li>Google Play Publishing</li>
                      <li>Customized Design</li>
                      <li>Free Hosting 1 Year</li>
                      <li>Free Domain 1 Year</li>
                    </ul>
                  </div>

                  <div className="bdpbl-box-acc">
                    <h4 onClick={() => showincfun("show3")}><span><ArrowRight /></span> View More Inclusions</h4>
                    <ul className={showinc3}>
                      <li>SEO Ready Website</li>
                      <li>Business E-Mail 15</li>
                      <li>SSL Certificate</li>
                      <li>Control Panel</li>
                      <li>Responsive Design</li>
                      <li>Content 2000 words</li>
                      <li>Social Media Integration</li>
                      <li>Google Analytics</li>
                      <li>Google Maps Integration</li>
                      <li>Advanced Live Chat Bot</li>
                      <li>Whatsapp Integration</li>
                      <li>Conversion Tracking and Pixel Integration</li>
                      <li>High Speed Guarantee</li>
                      <li>Search Console Integration</li>
                      <li>Social Login Option</li>
                      <li>Standard Ecommerce Policies</li>
                      <li>Payment Gateway Integration</li>
                    </ul>
                  </div>

                  <div className="bdpbl-box-footer">
                    <a href="https://wa.me/91204127124" target="_blank"><span className="icon"><WhatsApp /> 1204127124</span></a>
                  </div>
                </div>

                <div className="bdpbl-box">
                  <div className="bdpbl-box-header">
                    <h3>Diamond Website Package</h3>
                    <p>Advanced E-Commerce Website + Android App + iOS Hybrid App</p>
                    <div className="bdpbl-box-header-price">
                      <p><span>₹</span>114,999</p>
                      <span className="usdprice">($1439)</span>
                    </div>
                  </div>

                  <div className="bdpbl-box-body">
                    <ul>
                      <li>E-Commerce Website</li>
                      <li>Wallet and Referral Integration</li>
                      <li>Android Hybrid App</li>
                      <li>Google Play Publishing</li>
                      <li>iOS Hybrid APP</li>
                      <li>Customized Design</li>
                      <li>Free Hosting 1 Year</li>
                      <li>Free Domain 1 Year</li>
                    </ul>
                  </div>

                  <div className="bdpbl-box-acc">
                    <h4 onClick={() => showincfun("show4")}><span><ArrowRight /></span> View More Inclusions</h4>
                    <ul className={showinc4}>
                      <li>SEO Ready Website</li>
                      <li>Business E-Mail 15</li>
                      <li>SSL Certificate</li>
                      <li>Control Panel</li>
                      <li>Responsive Design</li>
                      <li>Content 2000 words</li>
                      <li>Social Media Integration</li>
                      <li>Google Analytics</li>
                      <li>Google Maps Integration</li>
                      <li>Advanced Live Chat Bot</li>
                      <li>Whatsapp Integration</li>
                      <li>Conversion Tracking and Pixel Integration</li>
                      <li>High Speed Guarantee</li>
                      <li>Search Console Integration</li>
                      <li>Social Login Option</li>
                      <li>Payment Gateway Integration</li>
                    </ul>
                  </div>

                  <div className="bdpbl-box-footer">
                    <a href="https://wa.me/91204127124" target="_blank"><span className="icon"><WhatsApp /> 1204127124</span></a>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="business-development-mid">
            <div className="siteWidth">
              <div className="business-development-mid-inner">

                <div className="bdmin">
                  <div className="bdmin-img">
                    <img src={shopify} alt="" />
                  </div>
                  <div className="bdmin-body">
                    <h4>Shopify Ecommerce Website</h4>
                    <p>Shopify is one of the most preferred E-commerce Platform by business owners. It is known for it's extremely easy to use panel. Shopify is an excellent solution if you are a small to medium business and you want a hassle free easy-to-use platform for your E-commerce Business.</p>
                  </div>
                </div>

                <div className="bdmin">
                  <div className="bdmin-img">
                    <img src={woocommerce} alt="" width={90} />
                  </div>
                  <div className="bdmin-body">
                    <h4>Woocommerce / WordPress Ecommerce Website</h4>
                    <p>If you are planning to sell a wide range of products and services to the customers directly through your website portal then a woocommerce eCommerce website will be a suitable choice.</p>
                  </div>
                </div>

                <div className="bdmin">
                  <div className="bdmin-img">
                    <img src={wordpress} alt="" />
                  </div>
                  <div className="bdmin-body">
                    <h4>Other Solutions</h4>
                    <p>At Techsell India we offer wide variety of ecommerce solutions for our clients. Reach out to us for a specific requirement</p>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </DocumentMeta>
  );
}
