import React from 'react'

import icon1c from "../../assets/images/mobile-app/c-icon1.webp"
import icon2c from "../../assets/images/mobile-app/c-icon2.webp"
import icon3c from "../../assets/images/mobile-app/c-icon3.webp"
import icon4c from "../../assets/images/mobile-app/c-icon4.webp"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

export default function Ratings(props) {
    return (
        <div className="mobile-content-sec-7">
            <div className="siteWidth">
                <div className="sh-title text-center">
                    <h2 className="title">{props.title}</h2>
                    <span></span>
                </div>
                <div className="mobile-content-sec-7-box">

                    <div className="box">
                        <div className="img">
                            <img src={icon1c} alt="" />
                        </div>
                        <div className="star">
                            <div>
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                            </div>

                            <span>4.8</span>
                        </div>
                        <p>Recognized as Top
                            App Developers by
                            Clutch.</p>
                    </div>

                    <div className="box">
                        <div className="img">
                            <img src={icon2c} alt="" />
                        </div>
                        <div className="star">
                            <span>4.8</span>
                        </div>
                        <p>Earned 99% of job success & “Top-Rated Plus” Badge on Upwork.</p>
                    </div>

                    <div className="box">
                        <div className="img">
                            <img src={icon3c} alt="" />
                        </div>
                        <div className="star">
                            <div>
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                            </div>
                            <span>5.0</span>
                        </div>
                        <p>Featured in the list of Top 10 Mobile App Development Companies.</p>
                    </div>

                    <div className="box">
                        <div className="img">
                            <img src={icon4c} alt="" />
                        </div>
                        <div className="star">
                            <div>
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                            </div>
                            <span>4.8</span>
                        </div>
                        <p>Ranked as “Top App Development Company” by GoodFirms.</p>
                    </div>

                </div>
            </div>
        </div>
    )
}
