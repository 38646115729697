import react, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Logo from "./Logo";
import Navigaton from "./Navigation";
import { Link } from "react-router-dom";
const whatsapp = <FontAwesomeIcon icon={faWhatsapp} />;

export default function Header() {
  const [isShowNav, setShowNav] = useState("false");
  const showNav = () => {
    setShowNav(!isShowNav);
  };

  return (
    <header className="site-header">
      <div className="siteWidth">
        <Logo />
        <div className="mob-contact">
          <a href="https://wa.me/911204127124" className="whatsappc">
            {whatsapp} 1204127124
          </a>
          <Link to="/contact" className="quote">
            Get A Free Quote
          </Link>
        </div>
        {/* display only desktops */}
        <div className="desk-nav">
          <Navigaton />
        </div>
        {/* display only desktops */}

        {/* display only mobiles */}
        <div className="mobile-nav">
          <span className="float-icon" onClick={showNav}>
            <span>
              <FontAwesomeIcon icon={faList} />
            </span>
          </span>
          <div className={isShowNav ? "notactive" : "navactive"}>
            <Navigaton />
          </div>
        </div>
        {/* display only mobiles */}
      </div>
    </header>
  );
}
