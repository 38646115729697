import Achievements from "./components/homepage/Achievements";
import BusinessSolutions from "./components/homepage/BusinessSolutions";
import Concepts from "./components/homepage/Concepts";
import DesignDevelopment from "./components/homepage/DesignDevelopment";
import HomeTabs from "./components/homepage/HomeTabs";
import HomeTechnologies from "./components/homepage/HomeTechnologies";
import DocumentMeta from "react-document-meta";
import { Link } from "react-router-dom";
import axios from "axios"
import { useForm } from "react-hook-form";
import { useState } from "react";
import Ratings from "./components/extras/Ratings";
import Reviews from "./components/extras/Reviews";
import PagePortfolio from "./components/extras/PagePortfolio";

export default function Home() {

  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [btn, setBtn] = useState("GET QUOTE");
  const onSubmit = (data) => {
    setBtn("Please wait...")
    axios.post("https://techsellindia.com/admin/api/contact_us", data).then((r) => {
      if (r.data.rcode == 200) {
        setBtn("ENQUIRY SENT")
        setTimeout(() => {
          setBtn("GET QUOTE")
        }, 2000);
      } else {
        setBtn("Something went wrong!")
      }
    })
  };


  const meta = {
    title:
      "The Professional IT Services That Brings Imagination to Your Business",
    description: "I am a description, and I can create multiple tags",
    canonical: "https://techsellindia.com/",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "react,meta,document,html,tags",
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="front-page">
        {/* head section */}
        <div className="head-section">
          <div className="siteWidth">
            <div className="head-section-left">
              <h1>
                Professional <span>Business Service </span>
                That Get Results
              </h1>
              <p>
                Though time brings suffocation to business. Your business may
                need fresh oxygen in terms of new creative strategies.
              </p>
              <div className="get_p">
                <Link to="/contact" className="btn-main">
                  Get Proposal
                </Link>
                <Link to="/business-solution" className="ml-3 btn-main-white">
                  Business Solution
                </Link>
              </div>
            </div>
            <div className="head-section-right">
              <div className="head-section-right-form">
                <h2>Get a Free Quote</h2>
                <form action="" onSubmit={handleSubmit(onSubmit)}>
                  <input type="hidden" className="form-control" {...register("page_name")} defaultValue="Business Solution" />
                  <input type="hidden" className="form-control" {...register("type")} defaultValue="Query" />
                  <input type="text" placeholder="Name" className="form-control" {...register("name", { required: true })} />
                  <input type="number" placeholder="Phone" className="form-control" {...register("phone", { required: true })} />
                  <input type="email" placeholder="Email" className="form-control" {...register("email", { required: true, pattern: /^\S+@\S+$/i })} />
                  <input type="text" placeholder="What is your project about?" className="form-control" {...register("message", { required: true })} />
                  <button>{btn}</button>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* head section */}

        {/* About us */}
        <div className="home-about">
          <div className="siteWidth">
            <h1>NO #1 Web And App design Development Company</h1>
            <p>
              We know how people interact with websites and mobile apps, so we
              create seamless but efficient user flows and attractive designs.
              Modern, but simple and clean UI and UX design is the shortest way
              to customers' loyalty. We have Developed Website across many
              Industries in the India and worldwide. Forget About your budget.
              Just call us your requirement in details. We will reach you at the
              earliest. Our professional Designers, developers and project
              management experts are all set to help your business to breathe
              healthy in this time of crisis.
            </p>
          </div>
        </div>
        {/* About us */}

        {/* Achievements */}
        <Achievements />
        {/* Achievements */}

        {/* Business Solutions */}
        <BusinessSolutions />
        {/* Business Solutions */}

        <div className="clear-both"></div>
        <div className=" mt-7"></div>
        <div className="clear-both"></div>
        <Ratings title={"Top Website Development Company"} />

        {/* Concepts */}
        <Concepts />
        {/* Concepts */}

        {/* Design & Development */}
        <DesignDevelopment />
        {/* Design & Development */}

        {/* Tabs */}
        <HomeTabs />
        {/* Tabs */}

        <div className="clear-both"></div>

        <PagePortfolio title={"Explore Our Websites Portfolio"} />

        {/* Technologies */}
        <HomeTechnologies />
        {/* Technologies */}

        <div className="clear-both"></div>
        <Reviews />

      </div>
    </DocumentMeta>
  );
}
