// import './App.css';
import { BrowserRouter } from "react-router-dom";

import Footer from "./includes/Footer";
import Header from "./includes/Header";
import Routers from "./Routers";
import ScrollToTop from "./scrollToTop";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="App">
        <Header />
        <div className="sitecontent">
          <Routers />
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
