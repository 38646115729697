import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

const fb = <FontAwesomeIcon icon={faFacebookF} />
const tw = <FontAwesomeIcon icon={faTwitter} />
const insta = <FontAwesomeIcon icon={faInstagram} />
const link = <FontAwesomeIcon icon={faLinkedinIn} />

export default function Social_Media_Links() {
    return (
        <div className="social-icons">
            <a href="https://www.facebook.com/techsellindia/" target="_blank" rel="noreferrer">
                {fb}
            </a>
            <a href="https://twitter.com/Techselli/" target="_blank" rel="noreferrer">
                {tw}
            </a>
            <a href="https://www.instagram.com/Techsell_india/" target="_blank" rel="noreferrer">
                {insta}
            </a>
            <a href="https://www.linkedin.com/company/techsell-india/" target="_blank" rel="noreferrer">
                {link}
            </a>
        </div>
    )
}