import React from 'react'
import { useState } from 'react'

import p1 from "../../../assets/images/portfolio/p-3/1.webp"
import p2 from "../../../assets/images/portfolio/p-3/2.webp"
import p3 from "../../../assets/images/portfolio/p-3/3.webp"
import p4 from "../../../assets/images/portfolio/p-3/4.webp"
import p5 from "../../../assets/images/portfolio/p-3/5.webp"

export default function P3() {
    const [img, setImg] = useState(p1)

    return (
        <div className="content-box">
            <div className="content-box-top">
                <div className="image-gallery">
                    <div className="big-img">
                        <img src={img} alt="" />
                    </div>
                    <div className="thumbs">
                        <img src={p1} alt="" onClick={() => setImg(p1)} />
                        <img src={p2} alt="" onClick={() => setImg(p2)} />
                        <img src={p3} alt="" onClick={() => setImg(p3)} />
                        <img src={p4} alt="" onClick={() => setImg(p4)} />
                        <img src={p5} alt="" onClick={() => setImg(p5)} />
                    </div>
                </div>

                <div className="details-box">
                    <div className="details-box-top">
                        <span>Website Name:</span>
                        <h2>Alivee</h2>
                        <p>Amazing smooth E-Commerce website that made in Laravel PHP.</p>
                    </div>
                    <div className="details-box-tech pt-5">
                        <h3>Technology used:</h3>
                        <ul>
                            <li>Laravel</li>
                            <li>Vanila CSS</li>
                            <li>Bootstrap</li>
                            <li>Animate.css</li>
                            <li>Font Awesome</li>
                            <li>Jquery</li>
                        </ul>

                        <a href="https://alivee.in/" target="_blank" className="btn btn-main btn-main-sm mt-5">Visit Website</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
