import { faSkype } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faLink,
  faLocationArrow,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DocumentMeta from "react-document-meta";
import axios from "axios"
import { useForm } from "react-hook-form";
import { useState } from "react";

export default function ContactUs() {

  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [btn, setBtn] = useState("GET QUOTE");
  const onSubmit = (data) => {
    setBtn("Please wait...")
    axios.post("https://techsellindia.com/admin/api/contact_us", data).then((r) => {
      if (r.data.rcode == 200) {
        setBtn("ENQUIRY SENT")
        setTimeout(() => {
          setBtn("GET QUOTE")
        }, 2000);
      } else {
        setBtn("Something went wrong!")
      }
    })
  };


  const meta = {
    title: "Get a free Quote for Software &amp; Mobile Development",
    description: "Get a free Quote in 24 hours for your idea or requirement of enterprise software &amp; mobile design, development, and testing.",
    canonical: "https://techsellindia.com/contact",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "react,meta,document,html,tags",
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="inner-page contact-page">
        <div className="contact-page-content">
          <div className="siteWidth">
            <h1>Got a project in mind?</h1>
            <p>We guarantee to get back to you within a business day.</p>

            <div className="contact-page-left">
              <h3>Contact Us</h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label>
                    Email<span className="requir">*</span>
                  </label>
                  <input type="text" placeholder="Email" className="form-control" {...register("email", { required: true })} />
                </div>

                <div className="form-group">
                  <label>
                    Full Name<span className="requir">*</span>
                  </label>
                  <input type="text" placeholder="Name" className="form-control" {...register("name", { required: true })} />
                </div>

                <div className="form-group">
                  <label>
                    Mobile Number<span className="requir">*</span>
                  </label>
                  <input type="text" placeholder="Phone" className="form-control" {...register("phone", { required: true })} />
                </div>
                <div className="form-group">
                  <label>Messege</label>
                  <textarea className="form-control" {...register("message", { required: true })}></textarea>
                </div>

                <div className="form-group">
                  <button type="submit" className="form-control">
                    {btn}
                  </button>
                </div>
              </form>
            </div>
            <div className="contact-page-right">
              <h3>More contacts</h3>
              <div className="more-contact">
                <div className="more-contact-box">
                  <h4>Project Enquiries</h4>
                  <a href="#">
                    <FontAwesomeIcon icon={faSkype} /> enquiry.techsellindia
                  </a>
                  <a href="mailto:sales@techsellindia.com">
                    <FontAwesomeIcon icon={faEnvelope} />{" "}
                    sales@techsellindia.com
                  </a>
                </div>
                <div className="more-contact-box">
                  <h4>New Business</h4>
                  <a href="mailto:info@techsellindia.com">
                    <FontAwesomeIcon icon={faEnvelope} /> info@techsellindia.com
                  </a>
                </div>
                <div className="more-contact-box">
                  <h4>Career</h4>
                  <a href="tel:+91-7669002505">
                    <FontAwesomeIcon icon={faPhoneAlt} /> +91-7669002505
                  </a>
                  <a href="mailto:hr@techsellindia.com">
                    <FontAwesomeIcon icon={faEnvelope} /> hr@techsellindia.com
                  </a>
                </div>
                <div className="more-contact-box">
                  <h4>General Enquiries</h4>
                  <a href="mailto:support@techsellindia.com">
                    <FontAwesomeIcon icon={faEnvelope} />{" "}
                    support@techsellindia.com
                  </a>
                </div>
              </div>

              <div className="locations">
                <div className="loaction-box">
                  <h3>Noida</h3>
                  <p>
                    <FontAwesomeIcon icon={faLocationArrow} /> G-3 Sector 22
                    Noida 201301.
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faLink} /> techsellindia.com 201301.
                  </p>
                  <p>
                    <a href="mailto:info@techsellindia.com">
                      <FontAwesomeIcon icon={faLink} /> info@techsellindia.com
                    </a>
                  </p>
                  <p>
                    <a href="tel:0120-4127124">
                      <FontAwesomeIcon icon={faPhoneAlt} /> 0120-4127124
                    </a>
                  </p>
                  <p>
                    <a href="tel:7982602803">
                      <FontAwesomeIcon icon={faPhoneAlt} /> 7982602803
                    </a>
                  </p>
                </div>
                <div className="loaction-box">
                  <h3>Kolkata</h3>
                  <p>
                    <FontAwesomeIcon icon={faLocationArrow} />{" "}
                    Baghajatin, 57 Birnagar,
                    <br />
                    Kolkata 700086
                    <br />
                    Near apollo pharmacy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DocumentMeta>
  );
}
