import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAndroid,
  faAngular,
  faApple,
  faLaravel,
  faPhp,
  faReact,
  faWordpress,
} from "@fortawesome/free-brands-svg-icons";

export default function HomeTechnologies() {
  return (
    <>
    <div className="clear-both"></div>
      <div className="mobile-content-sec-5">
        <div className="siteWidth">
          <div className="sh-title text-center">
            <h2 className="title">Technologies We Use</h2>
            <span></span>
          </div>

          <div className="mobile-content-sec-5-box">
            <div className="htb_icon">
              <FontAwesomeIcon icon={faLaravel} />
            </div>
            <div className="htb_icon">
              <FontAwesomeIcon icon={faReact} />
            </div>
            <div className="htb_icon">
              <FontAwesomeIcon icon={faAngular} />
            </div>
            <div className="htb_icon">
              <FontAwesomeIcon icon={faAndroid} />
            </div>
            <div className="htb_icon">
              <FontAwesomeIcon icon={faApple} />
            </div>
            <div className="htb_icon">
              <FontAwesomeIcon icon={faWordpress} />
            </div>
            <div className="htb_icon">
              <FontAwesomeIcon icon={faPhp} />
            </div>
          </div>
        </div>
      </div>
      <div className="clear-both"></div>
    </>
  );
}
