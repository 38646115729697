import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCode,
  faPencilAlt,
  faRocket,
  faSpellCheck,
  faStar,
} from "@fortawesome/free-solid-svg-icons";

const star = <FontAwesomeIcon icon={faStar} />;
const design = <FontAwesomeIcon icon={faPencilAlt} />;
const development = <FontAwesomeIcon icon={faCode} />;
const test = <FontAwesomeIcon icon={faSpellCheck} />;
const live = <FontAwesomeIcon icon={faRocket} />;

export default function Concepts() {
  return (
    <div className="home-concepts">
      <div className="siteWidth">
        <div className="home-concepts-main">
          <div className="home-concepts-box">
            <div className="home-concepts-box-in">
              <span className="hc_icon">
                <span>{star}</span>
                IDEA
              </span>
              <p>
                Our team is having wide experience in making innovative crafted
                ideas on business.
              </p>
            </div>
          </div>
          <div className="home-concepts-box">
            <div className="home-concepts-box-in">
              <span className="hc_icon">
                <span>{design}</span>
                Design
              </span>
              <p>
                We are experts on crafting project design with 100% satisfactory
                deliverance results.
              </p>
            </div>
          </div>
          <div className="home-concepts-box">
            <div className="home-concepts-box-in">
              <span className="hc_icon">
                <span>{development}</span>
                Development
              </span>
              <p>
                We are experts on crafting project design with 100% satisfactory
                deliverance results.
              </p>
            </div>
          </div>
          <div className="home-concepts-box">
            <div className="home-concepts-box-in">
              <span className="hc_icon">
                <span>{test}</span>
                Test
              </span>
              <p>
                We are always here to test, advise and solve business problems
                on any aspect of your website.
              </p>
            </div>
          </div>
          <div className="home-concepts-box">
            <div className="home-concepts-box-in">
              <span className="hc_icon">
                <span>{live}</span>
                Go Live
              </span>
              <p>
                We launch our projects on time with amazing results and brings
                it with a boom to the market.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
