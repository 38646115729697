import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckDouble,
  faLaughBeam,
  faTrophy,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";

const dbcheck = <FontAwesomeIcon icon={faCheckDouble} />;
const happycst = <FontAwesomeIcon icon={faLaughBeam} />;
const trophy = <FontAwesomeIcon icon={faTrophy} />;
const calender = <FontAwesomeIcon icon={faCalendarCheck} />;
export default function Achievements() {
  return (
    <div className="home-achivments">
      <div className="siteWidth">
        <h2 className="title-hd">
          <span>Our Achievements</span>
        </h2>
        <div className="home-achiv-box">
          <div className="home-achiv">
            <div className="home-achiv-in">
              <div className="ac_icon">{dbcheck}</div>
              <div className="ac_text">
                <p>
                  1000<span className="tl">+</span>
                </p>
                <span>Successful Projects</span>
              </div>
            </div>
          </div>

          <div className="home-achiv">
            <div className="home-achiv-in">
              <div className="ac_icon">{happycst}</div>
              <div className="ac_text">
                <p>
                  800<span className="tl">+</span>
                </p>
                <span>Happy Customers</span>
              </div>
            </div>
          </div>

          <div className="home-achiv">
            <div className="home-achiv-in">
              <div className="ac_icon">{trophy}</div>
              <div className="ac_text">
                <p>
                  98<span className="tl">%</span>
                </p>
                <span>Success Ratio</span>
              </div>
            </div>
          </div>

          <div className="home-achiv">
            <div className="home-achiv-in">
              <div className="ac_icon">{calender}</div>
              <div className="ac_text">
                <p>
                  4<span className="tl">%</span>
                </p>
                <span>Year of Experience</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
