import React from 'react'

import client1 from "../../assets/images/reviews/client1.webp"
import client2 from "../../assets/images/reviews/client2.webp"
import client3 from "../../assets/images/reviews/client3.webp"
import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';

export default function Reviews() {
  const settings = {
    lazyload: true,
    nav: false,
    items: 3,
    mouseDrag: true,
    responsive: {
      300: {
        items: 1
      },
      640: {
        items: 1
      },
      900: {
        items: 2
      },      
      1100: {
        items: 3
      }
    }
  };
  return (
    <div className="client-reviews mobile-content-sec-6">
      <div className="siteWidth">
        <div className="sh-title text-center">
          <h2 className="title">Customer Appreciation</h2>
          <span></span>
        </div>
        <TinySlider settings={settings}  >

          <div className="client-reviews-box">
            <div className="client-reviews-box-inner">

              <div className="review-box">
                <h5>They were highly recommended to me by my mentor and I am so glad that I chose to work with them.</h5>

                <p>This was the first app I've ever developed and they been extremely helpful every step of the way, answering questions, writing recommendations, and tips. If you're looking for a high-quality app, definitely go with Techsell India.</p>
              </div>
              <div className="client-box">
                <div className="client-img">
                  <img
                    className={`tns-lazy-img`}
                    data-src={client1}
                    alt=""
                  />
                </div>
                <div className="client-details">
                  <h6>Cramina Grande</h6>
                  <p>CEO | USA</p>
                </div>
              </div>
            </div>
          </div>

          <div className="client-reviews-box">
            <div className="client-reviews-box-inner">

              <div className="review-box">
                <h5>We were all quite demanding in terms of quality, but Space-O has managed to go beyond, to fulfill our expectations.</h5>

                <p>We searched online for app developers that had an extensive experience in mobile app development as well as a strong presence in the market. We chose Techsell India because of the up-close experience we had with another app they had developed </p>
              </div>
              <div className="client-box">
                <div className="client-img">
                  <img
                    className={`tns-lazy-img`}
                    data-src={client2}
                    alt=""
                  />
                </div>
                <div className="client-details">
                  <h6>Riccardo Petrachi</h6>
                  <p>CEO | USA</p>
                </div>
              </div>
            </div>
          </div>

          <div className="client-reviews-box">
            <div className="client-reviews-box-inner">

              <div className="review-box">
                <h5>Techsell India proved to be a professional service provider from the outset.</h5>

                <p>We appreciate their proactive approach and ability to suggest improvements to a prospective solution on both architectural and business levels. We know we can always rely on ScienceSoft's various competencies when our clients require quality software which would facilitate their business success.</p>
              </div>
              <div className="client-box">
                <div className="client-img">
                  <img
                    className={`tns-lazy-img`}
                    data-src={client3}
                    alt=""
                  />
                </div>
                <div className="client-details">
                  <h6>Mikhail Anfimau</h6>
                  <p>Senior Solution Architect | UK</p>
                </div>
              </div>
            </div>
          </div>

          <div className="client-reviews-box">
            <div className="client-reviews-box-inner">

              <div className="review-box">
                <h5>They were highly recommended to me by my mentor and I am so glad that I chose to work with them.</h5>

                <p>This was the first app I've ever developed and they been extremely helpful every step of the way, answering questions, writing recommendations, and tips. If you're looking for a high-quality app, definitely go with Techsell India.</p>
              </div>
              <div className="client-box">
                <div className="client-img">
                  <img
                    className={`tns-lazy-img`}
                    data-src={client1}
                    alt=""
                  />
                </div>
                <div className="client-details">
                  <h6>Cramina Grande</h6>
                  <p>CEO | USA</p>
                </div>
              </div>
            </div>
          </div>

          <div className="client-reviews-box">
            <div className="client-reviews-box-inner">

              <div className="review-box">
                <h5>We were all quite demanding in terms of quality, but Space-O has managed to go beyond, to fulfill our expectations.</h5>

                <p>We searched online for app developers that had an extensive experience in mobile app development as well as a strong presence in the market. We chose Techsell India because of the up-close experience we had with another app they had developed </p>
              </div>
              <div className="client-box">
                <div className="client-img">
                  <img
                    className={`tns-lazy-img`}
                    data-src={client2}
                    alt=""
                  />
                </div>
                <div className="client-details">
                  <h6>Riccardo Petrachi</h6>
                  <p>CEO | USA</p>
                </div>
              </div>
            </div>
          </div>

          <div className="client-reviews-box">
            <div className="client-reviews-box-inner">

              <div className="review-box">
                <h5>Techsell India proved to be a professional service provider from the outset.</h5>

                <p>We appreciate their proactive approach and ability to suggest improvements to a prospective solution on both architectural and business levels. We know we can always rely on ScienceSoft's various competencies when our clients require quality software which would facilitate their business success.</p>
              </div>
              <div className="client-box">
                <div className="client-img">
                  <img
                    className={`tns-lazy-img`}
                    data-src={client3}
                    alt=""
                  />
                </div>
                <div className="client-details">
                  <h6>Mikhail Anfimau</h6>
                  <p>Senior Solution Architect | UK</p>
                </div>
              </div>
            </div>
          </div>

        </TinySlider>

      </div>
    </div>
  )
}
