import React from "react";
import { Routes, Route, Switch } from "react-router-dom";
import Home from "./Home";
import About from "./Pages/Main/About";
import MobileAppDevelopment from "./Pages/Main/MobileAppDevelopment";
import WebDevelopment from "./Pages/Main/WebDevelopment";
import DigitalMarketing from "./Pages/Main/DigitalMarketing";
import WebsiteDesigning from "./Pages/Main/WebsiteDesigning";
import SharePoint from "./Pages/Main/SharePoint";
import ContactUs from "./Pages/Main/ContactUs";
import BusinessSolution from "./Pages/Main/BusinessSolution";
import PrivacyPolicy from "./Pages/Other/PrivacyPolicy";
import TermsAndConditions from "./Pages/Other/TermsAndConditions";
import Disclaimer from "./Pages/Other/Disclaimer";
import Covid19 from "./Pages/Other/Covid19";
import ReturnRefund from "./Pages/Other/ReturnRefund";
import Portfolio from "./Pages/Main/Portfolio";
const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route
        path="/mobile_app_development"
        element={<MobileAppDevelopment />}
      />
      <Route path="/web_development" element={<WebDevelopment />} />
      <Route path="/digital_marketing" element={<DigitalMarketing />} />
      <Route path="/website_designing" element={<WebsiteDesigning />} />
      <Route path="/sharepoint" element={<SharePoint />} />
      <Route path="/contact" element={<ContactUs />} />

      <Route path="/business-solution" element={<BusinessSolution />} />
      <Route path="/portfolio" element={<Portfolio />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/disclaimer" element={<Disclaimer />} />
      <Route path="/covid19" element={<Covid19 />} />
      <Route path="/return-refund" element={<ReturnRefund />} />
    </Routes>
  );
};
export default Routers;
