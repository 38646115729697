import React, { useState } from "react";
import DocumentMeta from "react-document-meta";
import { useForm } from "react-hook-form";
import axios from "axios"
import img1 from "../../assets/images/web-development/image-1.webp"
import icon1 from "../../assets/images/web-development/icon1.webp"
import icon2 from "../../assets/images/web-development/icon2.webp"
import icon3 from "../../assets/images/web-development/icon3.webp"
import icon4 from "../../assets/images/web-development/icon4.webp"
import icon5 from "../../assets/images/web-development/icon5.webp"
import icon6 from "../../assets/images/web-development/icon6.webp"
import Ratings from "../../components/extras/Ratings";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import Reviews from "../../components/extras/Reviews";
import HomeTechnologies from "../../components/homepage/HomeTechnologies";
import PagePortfolio from "../../components/extras/PagePortfolio";

export default function WebDevelopment() {
  const { register, handleSubmit } = useForm();
  const [btn, setBtn] = useState("SEND ME A PACKAGE");

  const meta = {
    title: "Web developers in USA Best website development company in USA",
    description:
      "Techsell India is a leading web development company in USA offering professional website and web application development services worldwide.",
    canonical: "https://techsellindia.com/web-development",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "Web developers in USA, web dessign, Web design agency in USA, Top web developers in USA,  New york web coders, NYC developement company, Best web service agency",
      },
    },
  };

  const onSubmit = (data) => {
    setBtn("Please wait...")
    axios.post("https://techsellindia.com/admin/api/contact_us", data).then((r) => {
      if (r.data.rcode === 200) {
        setBtn("MESSAGE SENT")
        setTimeout(() => {
          setBtn("SEND ME A PACKAGE")
        }, 2000);
      } else {
        setBtn("Something went wrong!")
      }
    })
  };

  const [expandedPanel, setExpandedPanel] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    console.log({ event, isExpanded });
    setExpandedPanel(isExpanded ? panel : false);
  };

  return (
    <DocumentMeta {...meta}>
      <div className="inner-page">
        <div className="mobile-app-content">
          <div className="mobile-header">
            <div className="siteWidth">
              <div className="mobile-header-content">
                <div className="mobile-header-left">
                  <h1>
                    Custom Web Application <br />Development Company
                  </h1>
                  <p>Looking for the best web application development company? We design and develop custom web applications for different industries. We offer end-to-end custom web development and app development services including technology consulting, custom app development, eCommerce solutions, CMS web apps, and web portal development.</p>
                  <div className="btn-box">
                    <a href="#counsult" className="btn btn-main">Get Free App Development Consultation</a>
                  </div>
                </div>
                <div className="mobile-header-right">
                  <img src={img1} alt="mobile app development company" />
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-content-sec-1">
            <div className="siteWidth">
              <div className="sh-title text-center">
                <h2 className="title">Custom Web Application Development Services</h2>
                <span></span>
              </div>

              <div className="mobile-content-sec-1-boxes">
                <div className="box">
                  <div className="box-head">
                    <div className="img">
                      <img src={icon1} alt="" />
                    </div>
                    <h3>Custom Web App Development</h3>
                  </div>
                  <div className="box-body">
                    <p>Want to use artificial intelligence or integrate functionality that is unique to your business needs? We, a top web app development company, develop web application development solutions that solve business challenges in healthcare, banking, retail, and manufacturing. We make sure the web solution is in line with your business requirements. Custom web apps streamline your business activities.</p>
                  </div>
                </div>

                <div className="box">
                  <div className="box-head">
                    <div className="img">
                      <img src={icon2} alt="" />
                    </div>
                    <h3>Cross-platform Apps</h3>
                  </div>
                  <div className="box-body">
                    <p>Do you want an application that runs seamlessly on the web as well as a mobile platform? Our web app development company develops web and mobile applications that work across operating systems, whether Android, iOS, or Windows and brings the best user experience. These apps function on all devices so as to help increase the audience reach and engagement of your brand.</p>
                  </div>
                </div>

                <div className="box">
                  <div className="box-head">
                    <div className="img">
                      <img src={icon3} alt="" />
                    </div>
                    <h3>E-commerce Solutions</h3>
                  </div>
                  <div className="box-body">
                    <p>Running an eCommerce company? Our web developers have developed 10+ eCommerce apps like Lacy, Sayyar, Florist One, and Hues Hub. We have developed eCommerce business solutions with the complete portal, shopping cart and product solutions, and a secure payment gateway integration. We make sure that each feature we integrate, makes running your eCommerce business smoothly.</p>
                  </div>
                </div>

                <div className="box">
                  <div className="box-head">
                    <div className="img">
                      <img src={icon4} alt="" />
                    </div>
                    <h3>Web Portal Development</h3>
                  </div>
                  <div className="box-body">
                    <p>Do you run a business that needs a web portal? Whether you are a startup or an enterprise business, we understand the business objectives of online businesses. To enhance your business activity, we develop platforms to easily communicate with customers. We develop enterprise portals like B2B, B2C, vendor portals and shopping, job, matrimony, dating, mailing, and various other types of web portals.</p>
                  </div>
                </div>

                <div className="box">
                  <div className="box-head">
                    <div className="img">
                      <img src={icon5} alt="" />
                    </div>
                    <h3>Custom CMS Web Development</h3>
                  </div>
                  <div className="box-body">
                    <p>Do you have security concerns or corporate security requirements in your CMS? Do you require advanced features? Sayyar is one of our recent projects where we designed and developed a customized content management system from scratch for the client. The result is that we mold a perfect CMS framework according to your business requirements and which you have full ownership of.</p>
                  </div>
                </div>

                <div className="box">
                  <div className="box-head">
                    <div className="img">
                      <img src={icon6} alt="" />
                    </div>
                    <h3>Web Application Consulting</h3>
                  </div>
                  <div className="box-body">
                    <p>Want to understand about the web application to grow your business? Being one of the leading custom web application development consultants, we have worked with over 1200 clients worldwide. We provide consulting with comprehensive support and find the beneficial business solution. We focus to become your reliable IT partner to discuss OS, server and technologies and platform.</p>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div className="mobile-content-sec-2">
            <div className="siteWidth">
              <h2>Looking for a Web Developer for Hire?</h2>
              <p>Being one of the best web app development companies, we know how to validate your idea and make it successful. Let's talk.</p>
              <a href="#counsult" className="btn btn-main">Book Your Free Consultation</a>
            </div>
          </div>

          <div className="mobile-content-sec-3" id="counsult">
            <div className="siteWidth">
              <div className="mobile-content-sec-3-content">
                <div className="left">
                  <div className="sh-title mb-5">
                    <h2 className="title">Top Web Development Company</h2>
                    <span></span>
                  </div>

                  <p>From concept to application development, Techsell India covers the entire business mobile app development cycle, no matter how diverse or complex your needs are. Our ability to meet your needs stem from our team of experts, who have years of experience in global app solution services and product engineering industries.</p>
                  <p>From successful startups like Glovo, Foodiini, and Bevy to our enterprise clients such as Nike, Saint-Gobain, McAfee, and Ferrari, we endeavor to streamline workflow, integrate functions into one seamless pattern, and in turn increase the productivity of a business enterprise.</p>
                  <p>We have a team of 200+ associates (UI/UX designers, mobile app developers, and quality analysts) in our work centers to deliver strategically designed and creatively crafted mobile application development services to take your business to the next level.</p>
                </div>
                <div className="right">
                  <div className="left-form" >
                    <h3>Talk to our experts now</h3>
                    <p><b>Validate your app idea with the field expert and clear all your doubts.</b></p>
                    <form action="" onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group">
                        <input type="hidden" className="form-control" {...register("page_name")} defaultValue="Digital Marketing" />
                      </div>
                      <div className="form-group">
                        <input type="hidden" className="form-control" {...register("type")} defaultValue="Website Audit" />
                      </div>
                      <div className="form-group">
                        <input type="text" placeholder="Name" className="form-control" {...register("name", { required: true })} />
                      </div>
                      <div className="form-group">
                        <input type="number" placeholder="Phone" className="form-control" {...register("phone", { required: true })} />
                      </div>
                      <div className="form-group">
                        <input type="email" placeholder="Email" className="form-control" {...register("email", { required: true, pattern: /^\S+@\S+$/i })} />
                      </div>
                      <div className="form-group">
                        <textarea placeholder="Werbsite URL" className="form-control" {...register("message", { required: true })}></textarea>
                      </div>
                      <button className="btn btn-main w-full">{btn}</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PagePortfolio title={"Explore Our Websites Portfolio"} />

          <HomeTechnologies />

          <Ratings title={"Top Website Development Company"} />

          <div className="mobile-content-sec-2">
            <div className="siteWidth">
              <h2>Want a amazing website for your business?</h2>
              <p>Being one of the best web app development companies, we know how to validate your idea and make it successful. Let's talk.</p>
              <a href="#counsult" className="btn btn-main">Book Your Free Consultation</a>
            </div>
          </div>

          <div className="mobile-content-sec-6">
            <div className="siteWidth">
              <div className="sh-title text-center">
                <h2 className="title">Web App Development Process</h2>
                <span></span>
              </div>

              <div className="mobile-content-sec-6-box">

                <div className="box">
                  <span className="big-text">01</span>
                  <p><b>Requirements Analysis</b></p>
                  <ul>
                    <li>Know your challenges</li>
                    <li>Analyzing your requirements</li>
                    <li>Propose a solution</li>
                  </ul>
                </div>

                <div className="box">
                  <span className="big-text">02</span>
                  <p><b>Research &amp; Consulting</b></p>
                  <ul>
                    <li>Make a business strategy</li>
                    <li>Concept validation</li>
                    <li>Creating execution plan</li>
                  </ul>
                </div>

                <div className="box">
                  <span className="big-text">03</span>
                  <p><b>Front-end Development</b></p>
                  <ul>
                    <li>Mapping user's journey</li>
                    <li>UI/UX design</li>
                    <li>Front-end architecture setup</li>
                  </ul>
                </div>

                <div className="box">
                  <span className="big-text">04</span>
                  <p><b>Back-end Development</b></p>
                  <ul>
                    <li>Back-end setup &amp; configuration</li>
                    <li>Third-party plugin integration</li>
                    <li>Seamless deployment</li>
                  </ul>
                </div>

                <div className="box">
                  <span className="big-text">05</span>
                  <p><b>QA and Testing</b></p>
                  <ul>
                    <li>Functional testing</li>
                    <li>Functional testing</li>
                    <li>Bug fixing</li>
                  </ul>
                </div>

                <div className="box">
                  <span className="big-text">06</span>
                  <p><b>Deployment &amp; Maintenance</b></p>
                  <ul>
                    <li>Deploy to your server</li>
                    <li>Web app available for users</li>
                    <li>Maintain web app if required</li>
                  </ul>
                </div>


              </div>
            </div>
          </div>
          <div className="mobile-content-sec-4">
            <div className="siteWidth">
              <div className="sh-title text-center">
                <h2 className="title">Frequently Asked Questions</h2>
                <span></span>
              </div>
              <div className="mt-7">
                <Accordion
                  expanded={expandedPanel === "panel1"}
                  onChange={handleAccordionChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    className="plan-box"
                  >
                    <b>What are the steps for hiring dedicated web application developers?</b>
                  </AccordionSummary>

                  <AccordionDetails>
                    <p>First of all, our sales executive discusses your requirements and understand what exactly you are looking for, like a mobile app, custom software or web design services. Then, we shortlist candidates for you to select a team easily. We'll send the shortlisted resumes to you for the final selection process. If you are not satisfied with resumes, we send some more options to you so you get to choose as per your requirement. After you give us the final confirmation and read terms of service, we then have a kick-off meeting to connect you with the web design and development team.</p>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expandedPanel === "panel2"}
                  onChange={handleAccordionChange("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    className="plan-box"
                  >
                    <b>How much do you charge for custom web app development services?</b>
                  </AccordionSummary>

                  <AccordionDetails>
                    <p>Being one of the best web application companies, we have highly skilled and experienced web developers who have worked on numerous projects and have a minimum of 4+ years of experience in their fields. Owing to this, our web development company charges $35 per hour for our bespoke web development operations. To get the exact custom web application development cost, you can contact us.</p>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expandedPanel === "panel3"}
                  onChange={handleAccordionChange("panel3")}
                >
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    className="plan-box"
                  >
                    <b>Will you sign an NDA with me?</b>
                  </AccordionSummary>

                  <AccordionDetails>
                    <p>Yes. Being a leading web app development agency, the security of your intellectual property is one of our top priorities and is a part of the services of the company. All the employees of our custom web application development company sign a legal contract stating that they won’t disclose confidential information of our customers to anyone else. Other than this, we ensure that we only share information about your project with people who are involved in it and give them limited access on a need-to-know basis. This helps us maintain the privacy of your application idea and assure you that your information is in safe hands.</p>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expandedPanel === "panel4"}
                  onChange={handleAccordionChange("panel4")}
                >
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    className="plan-box"
                  >
                    <b>Do I get to be a part of the web application development process?</b>
                  </AccordionSummary>

                  <AccordionDetails>
                    <p>Yes, you can. Being a custom web application development firm, we encourage this practice. We build web applications on our server and provide you with authorization code and credentials. So you can log in and monitor the progress of your ongoing website development.</p>

                    <p>We assign a project manager to help you with the development process and communicating your requirements. Moreover, you can directly contact the hired developer too through email, Skype, Basecamp, and phone calls. This will help you to share your requirements or changes if you want, such as features, web application development technologies, and frameworks.</p>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expandedPanel === "panel4"}
                  onChange={handleAccordionChange("panel4")}
                >
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    className="plan-box"
                  >
                    <b>What if I need some changes in the web-app after the development process is complete?</b>
                  </AccordionSummary>

                  <AccordionDetails>
                    <p>Depending on the kind of changes you want in your app the services you choose, our custom web app development consultants or dedicated team members will answer your queries and guide you accordingly. Moreover, if you need any changes when the development process is still on or till the app hasn't been launched, then simply contact your developer directly for the same.</p>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>

          <Reviews />

        </div>
      </div>
    </DocumentMeta>
  );
}
