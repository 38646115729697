import EmailUsBtn from "../components/EmailUsBtn";
import Logo from "./Logo";
import SocialMediaLinks from "./SocialMediaLinks";
import { Link } from "react-router-dom";
import {
  WhatsApp
} from "@mui/icons-material";

import client1 from "../assets/images/clients/client-1.webp"
import client2 from "../assets/images/clients/client-2.webp"
import client3 from "../assets/images/clients/client-3.webp"
import client4 from "../assets/images/clients/client-4.webp"

export default function Footer() {
  return (
    <footer className="site-footer">
      {/* Email us btn */}
      <EmailUsBtn />
      {/* Email us btn */}
      <div className="mainFooter">
        <div className="siteWidth">
          <div className="footer-left">
            <div className="footer-1">
              <div className="footer-1-top">
                <Logo />
                <div className="foot-awards">
                  <img
                    src={process.env.PUBLIC_URL + "/images/sdc.jpg"}
                    alt=""
                    className="max-w-full"
                  />
                  <img
                    src={process.env.PUBLIC_URL + "/images/trust.jpg"}
                    alt=""
                    className="max-w-full"
                  />
                  <img
                    src={process.env.PUBLIC_URL + "/images/topdeveloper.jpg"}
                    alt=""
                    className="max-w-full"
                  />
                </div>
              </div>
              <div className="footer-about pt-4">
                <p>
                  Techsell India is an innovative web agency based in India. Our
                  passion for helping small and medium size businesses has grown
                  us into a full-service strategic marketing company developing
                  online solutions for organizations across business sectors.
                </p>
              </div>

              <div className="foot-social pt-9">
                <SocialMediaLinks />
              </div>
              <div className="pt-9">
                <a href="https://payments.cashfree.com/forms/techsellindia" className="pay-sec" target="_blank">PAY SECURELY</a>
              </div>
            </div>
          </div>
          <div className="footer-right">
            <div className="footer-right-top">
              <div className="footer-2">
                <h6 className="foot-title">Services</h6>
                <div className="foot-menu">
                  <ul>
                    <li>
                      <Link to="/mobile_app_development">
                        Mobile App Development
                      </Link>
                    </li>
                    <li>
                      <Link to="/web_development">Web Development</Link>
                    </li>
                    <li>
                      <Link to="/digital_marketing">Digital Marketing</Link>
                    </li>
                    <li>
                      <Link to="/website_designing">Website Designing</Link>
                    </li>
                    <li>
                      <Link to="/sharepoint">SharePoint</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="footer-2">
                <h6 className="foot-title">Company</h6>
                <div className="foot-menu">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact US</Link>
                    </li>
                    <li>
                      <Link to="/about">About US</Link>
                    </li>
                    <li>
                      <Link to="/covid19">Covid-19</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="footer-2">
                <h6 className="foot-title">Policy</h6>
                <div className="foot-menu">
                  <ul>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/terms-and-conditions">Terms of Use</Link>
                    </li>
                    <li>
                      <Link to="/disclaimer">Disclaimer</Link>
                    </li>
                    <li>
                      <Link to="/return-refund">Return/Refund</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="footer-clients">
              <h6>Clients we worked for:</h6>
              <div className="footer-clients-logos">
                <img src={client1} alt="" />
                <img src={client2} alt="" />
                <img src={client3} alt="" />
                <img src={client4} alt="" />
              </div>
            </div>

          </div>

        </div>
      </div>
      <div className="footer-bottom">
        <div className="siteWidth">
          <p className="copyright">
            © 2017 - 2022 TechSellIndia Infotech (P) LTD. All Rights Reserved
          </p>
          <div className="dcma_status">
            <a
              href="https://www.dmca.com/Protection/Status.aspx?ID=bcef660d-14a9-4afa-af9d-be45ab409ff6&amp;refurl=https://techsellindia.com/"
              title="DMCA.com Protection Status"
              className="dmca-badge"
            >
              {" "}
              <img
                src="https://images.dmca.com/Badges/dmca_protected_sml_120m.png?ID=bcef660d-14a9-4afa-af9d-be45ab409ff6"
                alt="DMCA.com Protection Status"
              />
            </a>{" "}
            <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js">
              {" "}
            </script>
            <noscript>
              <img
                height="1"
                width="1"
                style="display:none;"
                alt=""
                src="https://px.ads.linkedin.com/collect/?pid=3752970&fmt=gif"
              />
            </noscript>
          </div>
        </div>
      </div>

      <div className="whatsapp">
        <a href="https://wa.me/911204127124">
          <div className="icon">
            <WhatsApp />
          </div>
        </a>
      </div>
    </footer>
  );
}
