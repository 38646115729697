import React from 'react'
import img2 from "../../assets/images/web-development/image-2.webp"
import img4 from "../../assets/images/web-development/image-3.webp"
import img3 from "../../assets/images/web-development/image-4.webp"
import icon7and from "../../assets/images/web-round.webp"

export default function PagePortfolio(props) {
    return (
        <div className="mobile-content-sec-4">
            <div className="siteWidth">
                <div className="sh-title text-center">
                    <h2 className="title">{props.title}</h2>
                    <span></span>
                </div>

                <div className="mobile-content-sec-4-box">

                    <div className="box">
                        <div className="img">
                            <img src={img2} alt="Best Mobile App Company" />
                        </div>
                        <div className="title-box">
                            <div className="sh-title">
                                <h3 className="title">Home6ers</h3>
                                <span></span>
                            </div>
                            <div className="icon">
                                <a href="https://home6ers.com/" target="_blank" rel="noreferrer">
                                    <img src={icon7and} alt="" width={46} />
                                </a>
                            </div>
                        </div>
                        <div className="details">
                            <p>Covered by Techcrunch, Glovo is the most downloaded on-demand delivery app in Spain. Developed by our mobile application developer team, this app has received total funding of $1.2B.</p>
                        </div>
                    </div>

                    <div className="box">
                        <div className="img">
                            <img src={img4} alt="Best Mobile App Company" />
                        </div>
                        <div className="title-box">
                            <div className="sh-title">
                                <h3 className="title">Dayuse</h3>
                                <span></span>
                            </div>
                            <div className="icon">
                                <a href="https://dayuse.in/" target="_blank" rel="noreferrer">
                                    <img src={icon7and} alt="" width={46} />
                                </a>
                            </div>
                        </div>
                        <div className="details">
                            <p>Covered by Techcrunch, Glovo is the most downloaded on-demand delivery app in Spain. Developed by our mobile application developer team, this app has received total funding of $1.2B.</p>
                        </div>
                    </div>

                    <div className="box">
                        <div className="img">
                            <img src={img3} alt="Best Mobile App Company" />
                        </div>
                        <div className="title-box">
                            <div className="sh-title">
                                <h3 className="title">Alivee</h3>
                                <span></span>
                            </div>
                            <div className="icon">
                                <a href="https://alivee.in/" target="_blank" rel="noreferrer">
                                    <img src={icon7and} alt="" width={46} />
                                </a>
                            </div>
                        </div>
                        <div className="details">
                            <p>Covered by Techcrunch, Glovo is the most downloaded on-demand delivery app in Spain. Developed by our mobile application developer team, this app has received total funding of $1.2B.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
