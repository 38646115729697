import React, { useState } from "react";
import DocumentMeta from "react-document-meta";
import { useForm } from "react-hook-form";
import axios from "axios"
import img1 from "../../assets/images/mobile-app/mobile-1.webp"
import img2 from "../../assets/images/mobile-app/image-2.webp"
import img3 from "../../assets/images/mobile-app/image-3.webp"
import img4 from "../../assets/images/mobile-app/image-4.webp"
import icon1 from "../../assets/images/mobile-app/icon1.webp"
import icon2 from "../../assets/images/mobile-app/icon2.webp"
import icon3 from "../../assets/images/mobile-app/icon3.webp"
import icon4 from "../../assets/images/mobile-app/icon4.webp"
import icon5 from "../../assets/images/mobile-app/icon5.webp"
import icon6 from "../../assets/images/mobile-app/icon6.webp"
import icon7and from "../../assets/images/android_round.webp"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import Ratings from "../../components/extras/Ratings";
import Reviews from "../../components/extras/Reviews";
import HomeTechnologies from "../../components/homepage/HomeTechnologies";

export default function MobileAppDevelopment() {
  const { register, handleSubmit } = useForm();
  const [btn, setBtn] = useState("SEND ME A PACKAGE");

  const meta = {
    title: "Android and IOS app development, Best App development company- Techsell India",
    description:
      "Expertise In Mobile App Development with multiple technoligies like React Native, Android, IOS, Anguler and Flutter.",
    canonical: "https://techsellindia.com/mobile-app-development",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "Android, developers, Best app developement company in USA, Android app development, custom mobile app developers,  API developement services, Top android developers in USA",
      },
    },
  };

  const onSubmit = (data) => {
    setBtn("Please wait...")
    axios.post("https://techsellindia.com/admin/api/contact_us", data).then((r) => {
      if (r.data.rcode === 200) {
        setBtn("MESSAGE SENT")
        setTimeout(() => {
          setBtn("SEND ME A PACKAGE")
        }, 2000);
      } else {
        setBtn("Something went wrong!")
      }
    })
  };

  const [expandedPanel, setExpandedPanel] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    console.log({ event, isExpanded });
    setExpandedPanel(isExpanded ? panel : false);
  };

  const settings = {
    lazyload: true,
    nav: false,
    items: 3,
    mouseDrag: true,
  };

  return (
    <DocumentMeta {...meta}>
      <div className="inner-page">
        <div className="mobile-app-content">
          <div className="mobile-header">
            <div className="siteWidth">
              <div className="mobile-header-content">
                <div className="mobile-header-left">
                  <h1>
                    Mobile App <br />Development Company
                  </h1>
                  <p>Searching for the top mobile app development company? Looking for the best mobile app development services provider? You are at the right place. We have designed and developed over 200+ Android and iOS-based mobile applications from scratch. Want to get your free quote?</p>
                  <div className="btn-box">
                    <a href="#counsult" className="btn btn-main">Get Free App Development Consultation</a>
                  </div>
                </div>
                <div className="mobile-header-right">
                  <img src={img1} alt="mobile app development company" />
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-content-sec-1">
            <div className="siteWidth">
              <div className="sh-title text-center">
                <h2 className="title">Mobile App Development Services</h2>
                <span></span>
              </div>

              <div className="mobile-content-sec-1-boxes">
                <div className="box">
                  <div className="box-head">
                    <div className="img">
                      <img src={icon1} alt="" />
                    </div>
                    <h3>iPhone App Development</h3>
                  </div>
                  <div className="box-body">
                    <p>We know what it requires to make a perfect iPhone application as we have already developed over 100+ iPhone apps of different categories. We know what it requires to turn a raw app idea into a successful mobile development.</p>
                  </div>
                </div>

                <div className="box">
                  <div className="box-head">
                    <div className="img">
                      <img src={icon2} alt="" />
                    </div>
                    <h3>Android App Development</h3>
                  </div>
                  <div className="box-body">
                    <p>Using the latest technology and industry experience, our full-stack Android developers develop successful Android apps that get funded and covered by Techcrunch, BBC News, and New YorkTimes.</p>
                  </div>
                </div>

                <div className="box">
                  <div className="box-head">
                    <div className="img">
                      <img src={icon3} alt="" />
                    </div>
                    <h3>Web App Development</h3>
                  </div>
                  <div className="box-body">
                    <p>Have a custom web app idea? Techsell India Technologies has an experienced mobile web app development team that is dedicated to delivering engaging mobile sites and apps with a user-friendly experience.</p>
                  </div>
                </div>

                <div className="box">
                  <div className="box-head">
                    <div className="img">
                      <img src={icon4} alt="" />
                    </div>
                    <h3>iPad App Development</h3>
                  </div>
                  <div className="box-body">
                    <p>With the deep understanding and expertise of native iPad app development services, our top app developers build the best iPad apps that add value to your business and your users’ lives.</p>
                  </div>
                </div>

                <div className="box">
                  <div className="box-head">
                    <div className="img">
                      <img src={icon5} alt="" />
                    </div>
                    <h3>Hire Dedicated Developers</h3>
                  </div>
                  <div className="box-body">
                    <p>Want to hire app developers to build a featureful mobile app? Hire our dedicated mobile app developers having experience in developing more than 4400 mobile apps such as Glovo, TOR app, and One8 app.</p>
                  </div>
                </div>

                <div className="box">
                  <div className="box-head">
                    <div className="img">
                      <img src={icon6} alt="" />
                    </div>
                    <h3>App Development Consulting</h3>
                  </div>
                  <div className="box-body">
                    <p>Want to build a scalable app? Get app development consultation services that match your business goals and help you drive maximum ROI. Our app consultants devise a strategy to offer result-oriented guidance, regardless of the app complexity.</p>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div className="mobile-content-sec-2">
            <div className="siteWidth">
              <h2>Want to Create Your Native Application?</h2>
              <p>We have experience in developing over 4400 Android and iOS-based application solutions. Let's convert your idea into reality. Schedule your free consultation with the top mobile app development firm.</p>
              <a href="#counsult" className="btn btn-main">Book Your Free Consultation</a>
            </div>
          </div>

          <div className="mobile-content-sec-3" id="counsult">
            <div className="siteWidth">
              <div className="mobile-content-sec-3-content">
                <div className="left">
                  <div className="sh-title mb-5">
                    <h2 className="title">Top App Development Company</h2>
                    <span></span>
                  </div>

                  <p>From concept to application development, Techsell India covers the entire business mobile app development cycle, no matter how diverse or complex your needs are. Our ability to meet your needs stem from our team of experts, who have years of experience in global app solution services and product engineering industries.</p>
                  <p>From successful startups like Glovo, Foodiini, and Bevy to our enterprise clients such as Nike, Saint-Gobain, McAfee, and Ferrari, we endeavor to streamline workflow, integrate functions into one seamless pattern, and in turn increase the productivity of a business enterprise.</p>
                  <p>We have a team of 200+ associates (UI/UX designers, mobile app developers, and quality analysts) in our work centers to deliver strategically designed and creatively crafted mobile application development services to take your business to the next level.</p>
                </div>
                <div className="right">
                  <div className="left-form">
                    <h3>Talk to our experts now</h3>
                    <p><b>Validate your app idea with the field expert and clear all your doubts.</b></p>
                    <form action="" onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group">
                        <input type="hidden" className="form-control" {...register("page_name")} defaultValue="Digital Marketing" />
                      </div>
                      <div className="form-group">
                        <input type="hidden" className="form-control" {...register("type")} defaultValue="Website Audit" />
                      </div>
                      <div className="form-group">
                        <input type="text" placeholder="Name" className="form-control" {...register("name", { required: true })} />
                      </div>
                      <div className="form-group">
                        <input type="number" placeholder="Phone" className="form-control" {...register("phone", { required: true })} />
                      </div>
                      <div className="form-group">
                        <input type="email" placeholder="Email" className="form-control" {...register("email", { required: true, pattern: /^\S+@\S+$/i })} />
                      </div>
                      <div className="form-group">
                        <textarea placeholder="Werbsite URL" className="form-control" {...register("message", { required: true })}></textarea>
                      </div>
                      <button className="btn btn-main w-full">{btn}</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mobile-content-sec-4">
            <div className="siteWidth">
              <div className="sh-title text-center">
                <h2 className="title">Explore Our Mobile App Portfolio</h2>
                <span></span>
              </div>

              <div className="mobile-content-sec-4-box">

                <div className="box">
                  <div className="img">
                    <img src={img2} alt="Best Mobile App Company" />
                  </div>
                  <div className="title-box">
                    <div className="sh-title">
                      <h3 className="title">List Your Pics</h3>
                      <span></span>
                    </div>
                    <div className="icon">
                      <img src={icon7and} alt="" width={46} />
                    </div>
                  </div>
                  <div className="details">
                    <p>Covered by Techcrunch, Glovo is the most downloaded on-demand delivery app in Spain. Developed by our mobile application developer team, this app has received total funding of $1.2B.</p>
                  </div>
                </div>

                <div className="box">
                  <div className="img">
                    <img src={img4} alt="Best Mobile App Company" />
                  </div>
                  <div className="title-box">
                    <div className="sh-title">
                      <h3 className="title">Texly</h3>
                      <span></span>
                    </div>
                    <div className="icon">
                      <img src={icon7and} alt="" width={46} />
                    </div>
                  </div>
                  <div className="details">
                    <p>Covered by Techcrunch, Glovo is the most downloaded on-demand delivery app in Spain. Developed by our mobile application developer team, this app has received total funding of $1.2B.</p>
                  </div>
                </div>

                <div className="box">
                  <div className="img">
                    <img src={img3} alt="Best Mobile App Company" />
                  </div>
                  <div className="title-box">
                    <div className="sh-title">
                      <h3 className="title">Shramik Jobs</h3>
                      <span></span>
                    </div>
                    <div className="icon">
                      <img src={icon7and} alt="" width={46} />
                    </div>
                  </div>
                  <div className="details">
                    <p>Covered by Techcrunch, Glovo is the most downloaded on-demand delivery app in Spain. Developed by our mobile application developer team, this app has received total funding of $1.2B.</p>
                  </div>
                </div>


              </div>


            </div>
          </div>

          <HomeTechnologies />

          <Ratings title={"Top Mobile App Development Company"} />

          <div className="mobile-content-sec-2">
            <div className="siteWidth">
              <h2>Want to Create Your Native Application?</h2>
              <p>We have experience in developing over 4400 Android and iOS-based application solutions. Let's convert your idea into reality. Schedule your free consultation with the top mobile app development firm.</p>
              <a href="#counsult" className="btn btn-main">Book Your Free Consultation</a>
            </div>
          </div>

          <div className="mobile-content-sec-6">
            <div className="siteWidth">
              <div className="sh-title text-center">
                <h2 className="title">Our Mobile App Development Process</h2>
                <span></span>
              </div>

              <div className="mobile-content-sec-6-box">

                <div className="box">
                  <span className="big-text">01</span>
                  <p><b>Analysis of Requirements</b></p>
                  <ul>
                    <li>Get to know you</li>
                    <li>Comprehending your requirements</li>
                    <li>Proposing a solution</li>
                  </ul>
                </div>

                <div className="box">
                  <span className="big-text">02</span>
                  <p><b>App Development Consultation</b></p>
                  <ul>
                    <li>Identify your competitors</li>
                    <li>Establish goals &amp; objectives</li>
                    <li>Get your feedback</li>
                  </ul>
                </div>

                <div className="box">
                  <span className="big-text">03</span>
                  <p><b>Wireframing &amp; Designing</b></p>
                  <ul>
                    <li>Blueprint structure</li>
                    <li>Wireframing each screen</li>
                    <li>Designing the app</li>
                  </ul>
                </div>

                <div className="box">
                  <span className="big-text">04</span>
                  <p><b>Development</b></p>
                  <ul>
                    <li>Coding the app</li>
                    <li>Layout main sections</li>
                    <li>Get your feedback</li>
                  </ul>
                </div>

                <div className="box">
                  <span className="big-text">05</span>
                  <p><b>Testing</b></p>
                  <ul>
                    <li>Application testing</li>
                    <li>100% bug-free</li>
                    <li>Get final approval from you</li>
                  </ul>
                </div>

                <div className="box">
                  <span className="big-text">06</span>
                  <p><b>Deployment</b></p>
                  <ul>
                    <li>Deploy the app to your server</li>
                    <li>Deploy to App Stores</li>
                    <li>App available for users</li>
                  </ul>
                </div>


              </div>
            </div>
          </div>
          <div className="mobile-content-sec-4">
            <div className="siteWidth">
              <div className="sh-title text-center">
                <h2 className="title">Frequently Asked Questions</h2>
                <span></span>
              </div>
              <div className="mt-7">
                <Accordion
                  expanded={expandedPanel === "panel1"}
                  onChange={handleAccordionChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    className="plan-box"
                  >
                    <b>How much does it cost to develop a mobile app?</b>
                  </AccordionSummary>

                  <AccordionDetails>
                    <p>The cost of developing an app can range anything from $2000-$300000. This is because you need to consider various factors including app platform, features, web and mobile app development company, developer's charge among others. Simple apps cost less while complex apps cost more depending on the functionality.</p>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expandedPanel === "panel2"}
                  onChange={handleAccordionChange("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    className="plan-box"
                  >
                    <b>How much time does it take to develop an app?</b>
                  </AccordionSummary>

                  <AccordionDetails>
                    <p>The average time required to develop an app can go from 2-3 weeks to 9+ months. We consider multiple variables like the number of features and their complexity, uniqueness of the design, development complications, use of third-party services, and so on.</p>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expandedPanel === "panel3"}
                  onChange={handleAccordionChange("panel3")}
                >
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    className="plan-box"
                  >
                    <b>Will you provide documentation for my solution or mobile app development? Do I own the code?</b>
                  </AccordionSummary>

                  <AccordionDetails>
                    <p>Yes, being the best mobile app software development company, we provide complete documentation for the mobile app software or solution that we develop for your business. Being a leading mobile app development agency, we sign a non-disclosure agreement with all the clients. At the end of the project, you own the code of your app or mobile solution.</p>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expandedPanel === "panel4"}
                  onChange={handleAccordionChange("panel4")}
                >
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    className="plan-box"
                  >
                    <b>What are the different features that you have integrated into the mobile apps developed by you?</b>
                  </AccordionSummary>

                  <AccordionDetails>
                    <p>We have integrated a range of latest and unique features in the mobile applications as demanded by our clients. Here are some of the must-have features we have integrated into the apps:</p>
                    <ul>
                      <li>Payment Gateway Integration</li>
                      <li>Map Integration</li>
                      <li>Real-Time Tracking</li>
                      <li>Live Chat Support</li>
                      <li>In-app Messaging</li>
                      <li>Machine Learning Integration</li>
                      <li>Multi-lingual Support</li>
                      <li>Cross-Platform Ability</li>
                      <li>Push Notification</li>
                      <li>Social Media Integration</li>
                      <li>Search Bar With Filters</li>
                    </ul>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expandedPanel === "panel4"}
                  onChange={handleAccordionChange("panel4")}
                >
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    className="plan-box"
                  >
                    <b>How will you keep me updated on the progress of the project? What If I need any changes in the app or mobile solution?</b>
                  </AccordionSummary>

                  <AccordionDetails>
                    <p>We will assign a project manager to you. S/he will keep in touch with you throughout the project and keep you updated about the project progress. You can communicate your requirements or the changes with the assigned project manager and he will communicate your requirements and changes with the developers.</p>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expandedPanel === "panel5"}
                  onChange={handleAccordionChange("panel5")}
                >
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    className="plan-box"
                  >
                    <b>Which tools do you use for project management?</b>
                  </AccordionSummary>

                  <AccordionDetails>
                    <p>We use Basecamp to manage your mobile app development project and keep you updated about everything related to the development progress.</p>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expandedPanel === "panel6"}
                  onChange={handleAccordionChange("panel6")}
                >
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    className="plan-box"
                  >
                    <b>What are the security measures you take to keep the app secure and stable?</b>
                  </AccordionSummary>

                  <AccordionDetails>
                    <p>We do follow a certain process.</p>
                    <ul>
                      <li>We sign an NDA before starting a project</li>
                      <li>Disallow access to your app's content providers</li>
                      <li>Apply network security measures</li>
                      <li>Secure office environment constant monitoring at the workplace</li>
                    </ul>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expandedPanel === "panel7"}
                  onChange={handleAccordionChange("panel7")}
                >
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    className="plan-box"
                  >
                    <b>Will you assist me to upload my mobile app on the App Stores (Google Play Store and Apple App Store)?</b>
                  </AccordionSummary>

                  <AccordionDetails>
                    <p>Yes, of course. We will upload your mobile app on the App Stores, including Apple App Store and Google Play Store as our mobile app development process.</p>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expandedPanel === "panel8"}
                  onChange={handleAccordionChange("panel8")}
                >
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    className="plan-box"
                  >
                    <b>Will you provide any support after the completion of the project successfully?</b>
                  </AccordionSummary>

                  <AccordionDetails>
                    <p>Yes, we provide 3-months free technical support, so in case, if you face any technical problems while accessing the app, our support team will guide you.</p>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>

          <Reviews />

        </div>
      </div>
    </DocumentMeta>
  );
}
