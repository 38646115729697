import React, { useState } from "react";
import DocumentMeta from "react-document-meta";
import { useForm } from "react-hook-form";

import axios from "axios"
import img1 from "../../assets/images/sharepoint/image-1.webp"
import icon1 from "../../assets/images/sharepoint/icon1.webp"
import icon2 from "../../assets/images/sharepoint/icon2.webp"
import icon3 from "../../assets/images/sharepoint/icon3.webp"
import icon4 from "../../assets/images/sharepoint/icon4.webp"
import icon5 from "../../assets/images/sharepoint/icon5.webp"
import icon6 from "../../assets/images/sharepoint/icon6.webp"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import Ratings from "../../components/extras/Ratings";
import Reviews from "../../components/extras/Reviews";
import HomeTechnologies from "../../components/homepage/HomeTechnologies";
import PagePortfolio from "../../components/extras/PagePortfolio";

export default function SharePoint() {
  const { register, handleSubmit } = useForm();
  const [btn, setBtn] = useState("SEND ME A PACKAGE");

  const meta = {
    title: "Best sharepoint developement company in USA",
    description:
      "We provide businesses access to full-suite of Microsoft SharePoint development services for easy customization, consulting, integration and support",
    canonical: "https://techsellindia.com/sharepoint",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "Sharepoint developement company in USA, Custom sharepoint developement, Microsoft sharepoint developement services In USA, Sharepoint company in USA",
      },
    },
  };

  const onSubmit = (data) => {
    setBtn("Please wait...")
    axios.post("https://techsellindia.com/admin/api/contact_us", data).then((r) => {
      if (r.data.rcode === 200) {
        setBtn("MESSAGE SENT")
        setTimeout(() => {
          setBtn("SEND ME A PACKAGE")
        }, 2000);
      } else {
        setBtn("Something went wrong!")
      }
    })
  };

  const [expandedPanel, setExpandedPanel] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    console.log({ event, isExpanded });
    setExpandedPanel(isExpanded ? panel : false);
  };

  const settings = {
    lazyload: true,
    nav: false,
    items: 3,
    mouseDrag: true,
  };

  return (
    <DocumentMeta {...meta}>
      <div className="inner-page">
        <div className="mobile-app-content">

          <div className="mobile-header">
            <div className="siteWidth">
              <div className="mobile-header-content">
                <div className="mobile-header-left">
                  <h1>
                    SharePoint <br />Development Company
                  </h1>
                  <p>If you are looking to leverage this platform, Techsell India is a trusted partner that can help you with its implementation. We offer end-to-end SharePoint services, from SharePoint consulting, to design, integration, and implementation. Our SharePoint specialists are capable of handling your SharePoint upgrade or migration requirements as well.</p>
                  <div className="btn-box">
                    <a href="#counsult" className="btn btn-main">Get Free Sharepoint Demo</a>
                  </div>
                </div>
                <div className="mobile-header-right">
                  <img src={img1} alt="mobile app development company" />
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-content-sec-1">
            <div className="siteWidth">
              <div className="sh-title text-center">
                <h2 className="title">Custom SharePoint Development Services</h2>
                <span></span>
              </div>

              <div className="mobile-content-sec-1-boxes">
                <div className="box">
                  <div className="box-head">
                    <div className="img">
                      <img src={icon1} alt="" />
                    </div>
                    <h3>SharePoint Development</h3>
                  </div>
                  <div className="box-body">
                    <p>We have a team of SharePoint consultants who provide SharePoint solutions with help of the multipurpose Sharepoint platform and its implementation in alignment with the evolving nature of your business.</p>
                  </div>
                </div>

                <div className="box">
                  <div className="box-head">
                    <div className="img">
                      <img src={icon2} alt="" />
                    </div>
                    <h3>Business Process Automation</h3>
                  </div>
                  <div className="box-body">
                    <p>We have a team of SharePoint developers who are highly skilled in automating business processes through workflow development to improve efficiency and increase teamwork in your company.</p>
                  </div>
                </div>

                <div className="box">
                  <div className="box-head">
                    <div className="img">
                      <img src={icon3} alt="" />
                    </div>
                    <h3>Branding and Design </h3>
                  </div>
                  <div className="box-body">
                    <p>Branding & design are key elements for any intranet and our UI design team works with SharePoint Development Team to implement engaging and beautiful UI which meets customer's unique brand vision.</p>
                  </div>
                </div>

                <div className="box">
                  <div className="box-head">
                    <div className="img">
                      <img src={icon4} alt="" />
                    </div>
                    <h3>SharePoint Migration</h3>
                  </div>
                  <div className="box-body">
                    <p>If you are planning to migrate to a newer SharePoint version, we have expertise in version upgrade, data migration and optimization to the newer environment as a SharePoint Development Company.</p>
                  </div>
                </div>

                <div className="box">
                  <div className="box-head">
                    <div className="img">
                      <img src={icon5} alt="" />
                    </div>
                    <h3>SharePoint Healthcheck</h3>
                  </div>
                  <div className="box-body">
                    <p>Our sharepoint architects and developers identify current and potential issues in the infrastructure, architecture and performance and suggest corrective actions to make sure you avail full benefit of your implementation.</p>
                  </div>
                </div>

                <div className="box">
                  <div className="box-head">
                    <div className="img">
                      <img src={icon6} alt="" />
                    </div>
                    <h3>SharePoint Integration </h3>
                  </div>
                  <div className="box-body">
                    <p>Our expert SharePoint Consultants understand your requirements and integrate enterprise systems like ERP, CRM, BI etc. inside the workspace of employee intranet using Microsoft SharePoint Services.</p>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div className="mobile-content-sec-2">
            <div className="siteWidth">
              <h2>Looking for a SharePoint Developer for Hire?</h2>
              <p>Being one of the best SharePoint development companies, we know how to validate your idea and make it successful. Let's talk.</p>
              <a href="#counsult" className="btn btn-main">Book Your Free Consultation</a>
            </div>
          </div>

          <div className="mobile-content-sec-3" id="counsult">
            <div className="siteWidth">
              <div className="mobile-content-sec-3-content">
                <div className="left">
                  <div className="sh-title mb-5">
                    <h2 className="title">Top SharePoint Company</h2>
                    <span></span>
                  </div>

                  <p>From concept to application development, Techsell India covers the entire business mobile app development cycle, no matter how diverse or complex your needs are. Our ability to meet your needs stem from our team of experts, who have years of experience in global app solution services and product engineering industries.</p>
                  <p>From successful startups like Glovo, Foodiini, and Bevy to our enterprise clients such as Nike, Saint-Gobain, McAfee, and Ferrari, we endeavor to streamline workflow, integrate functions into one seamless pattern, and in turn increase the productivity of a business enterprise.</p>
                  <p>We have a team of 200+ associates (UI/UX designers, mobile app developers, and quality analysts) in our work centers to deliver strategically designed and creatively crafted mobile application development services to take your business to the next level.</p>
                </div>
                <div className="right">
                  <div className="left-form" >
                    <h3>Talk to our experts now</h3>
                    <p><b>Validate your app idea with the field expert and clear all your doubts.</b></p>
                    <form action="" onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group">
                        <input type="hidden" className="form-control" {...register("page_name")} defaultValue="Digital Marketing" />
                      </div>
                      <div className="form-group">
                        <input type="hidden" className="form-control" {...register("type")} defaultValue="Website Audit" />
                      </div>
                      <div className="form-group">
                        <input type="text" placeholder="Name" className="form-control" {...register("name", { required: true })} />
                      </div>
                      <div className="form-group">
                        <input type="number" placeholder="Phone" className="form-control" {...register("phone", { required: true })} />
                      </div>
                      <div className="form-group">
                        <input type="email" placeholder="Email" className="form-control" {...register("email", { required: true, pattern: /^\S+@\S+$/i })} />
                      </div>
                      <div className="form-group">
                        <textarea placeholder="Werbsite URL" className="form-control" {...register("message", { required: true })}></textarea>
                      </div>
                      <button className="btn btn-main w-full">{btn}</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PagePortfolio title={"Explore Our Websites Portfolio"} />

          <HomeTechnologies />

          <Ratings title={"Top Sharepoint Development Company"} />

          <div className="mobile-content-sec-2">
            <div className="siteWidth">
              <h2>Want a amazing website for your business?</h2>
              <p>Being one of the best web app development companies, we know how to validate your idea and make it successful. Let's talk.</p>
              <a href="#counsult" className="btn btn-main">Book Your Free Consultation</a>
            </div>
          </div>

          <div className="mobile-content-sec-6">
            <div className="siteWidth">
              <div className="sh-title text-center">
                <h2 className="title">SharePoint Designing Process</h2>
                <span></span>
              </div>

              <div className="mobile-content-sec-6-box">

                <div className="box">
                  <span className="big-text">01</span>
                  <p><b>Requirements Analysis</b></p>
                  <ul>
                    <li>Know your challenges</li>
                    <li>Analyzing your requirements</li>
                    <li>Propose a solution</li>
                  </ul>
                </div>

                <div className="box">
                  <span className="big-text">02</span>
                  <p><b>Research &amp; Consulting</b></p>
                  <ul>
                    <li>Make a business strategy</li>
                    <li>Concept validation</li>
                    <li>Creating execution plan</li>
                  </ul>
                </div>

                <div className="box">
                  <span className="big-text">03</span>
                  <p><b>Front-end Development</b></p>
                  <ul>
                    <li>Mapping user's journey</li>
                    <li>UI/UX design</li>
                    <li>Front-end architecture setup</li>
                  </ul>
                </div>

                <div className="box">
                  <span className="big-text">04</span>
                  <p><b>Back-end Development</b></p>
                  <ul>
                    <li>Back-end setup &amp; configuration</li>
                    <li>Third-party plugin integration</li>
                    <li>Seamless deployment</li>
                  </ul>
                </div>

                <div className="box">
                  <span className="big-text">05</span>
                  <p><b>QA and Testing</b></p>
                  <ul>
                    <li>Functional testing</li>
                    <li>Functional testing</li>
                    <li>Bug fixing</li>
                  </ul>
                </div>

                <div className="box">
                  <span className="big-text">06</span>
                  <p><b>Deployment &amp; Maintenance</b></p>
                  <ul>
                    <li>Deploy to your server</li>
                    <li>Web app available for users</li>
                    <li>Maintain web app if required</li>
                  </ul>
                </div>


              </div>
            </div>
          </div>
          <div className="mobile-content-sec-4">
            <div className="siteWidth">
              <div className="sh-title text-center">
                <h2 className="title">Frequently Asked Questions</h2>
                <span></span>
              </div>
              <div className="mt-7">
                <Accordion
                  expanded={expandedPanel === "panel1"}
                  onChange={handleAccordionChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    className="plan-box"
                  >
                    <b>What are the steps for hiring dedicated web application developers?</b>
                  </AccordionSummary>

                  <AccordionDetails>
                    <p>First of all, our sales executive discusses your requirements and understand what exactly you are looking for, like a mobile app, custom software or web design services. Then, we shortlist candidates for you to select a team easily. We'll send the shortlisted resumes to you for the final selection process. If you are not satisfied with resumes, we send some more options to you so you get to choose as per your requirement. After you give us the final confirmation and read terms of service, we then have a kick-off meeting to connect you with the web design and development team.</p>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expandedPanel === "panel2"}
                  onChange={handleAccordionChange("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    className="plan-box"
                  >
                    <b>How much do you charge for custom web app development services?</b>
                  </AccordionSummary>

                  <AccordionDetails>
                    <p>Being one of the best web application companies, we have highly skilled and experienced web developers who have worked on numerous projects and have a minimum of 4+ years of experience in their fields. Owing to this, our web development company charges $35 per hour for our bespoke web development operations. To get the exact custom web application development cost, you can contact us.</p>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expandedPanel === "panel3"}
                  onChange={handleAccordionChange("panel3")}
                >
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    className="plan-box"
                  >
                    <b>Will you sign an NDA with me?</b>
                  </AccordionSummary>

                  <AccordionDetails>
                    <p>Yes. Being a leading web app development agency, the security of your intellectual property is one of our top priorities and is a part of the services of the company. All the employees of our custom web application development company sign a legal contract stating that they won’t disclose confidential information of our customers to anyone else. Other than this, we ensure that we only share information about your project with people who are involved in it and give them limited access on a need-to-know basis. This helps us maintain the privacy of your application idea and assure you that your information is in safe hands.</p>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expandedPanel === "panel4"}
                  onChange={handleAccordionChange("panel4")}
                >
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    className="plan-box"
                  >
                    <b>Do I get to be a part of the web application development process?</b>
                  </AccordionSummary>

                  <AccordionDetails>
                    <p>Yes, you can. Being a custom web application development firm, we encourage this practice. We build web applications on our server and provide you with authorization code and credentials. So you can log in and monitor the progress of your ongoing website development.</p>

                    <p>We assign a project manager to help you with the development process and communicating your requirements. Moreover, you can directly contact the hired developer too through email, Skype, Basecamp, and phone calls. This will help you to share your requirements or changes if you want, such as features, web application development technologies, and frameworks.</p>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expandedPanel === "panel4"}
                  onChange={handleAccordionChange("panel4")}
                >
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    className="plan-box"
                  >
                    <b>What if I need some changes in the web-app after the development process is complete?</b>
                  </AccordionSummary>

                  <AccordionDetails>
                    <p>Depending on the kind of changes you want in your app the services you choose, our custom web app development consultants or dedicated team members will answer your queries and guide you accordingly. Moreover, if you need any changes when the development process is still on or till the app hasn't been launched, then simply contact your developer directly for the same.</p>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>

          <Reviews />
        </div>
      </div>
    </DocumentMeta>
  );
}
