import React from 'react'
import { useState } from 'react'

import p1 from "../../../assets/images/portfolio/p-1/1.webp"
import p2 from "../../../assets/images/portfolio/p-1/2.webp"
import p3 from "../../../assets/images/portfolio/p-1/3.webp"
import p4 from "../../../assets/images/portfolio/p-1/4.webp"
import p5 from "../../../assets/images/portfolio/p-1/5.webp"
import p6 from "../../../assets/images/portfolio/p-1/6.webp"
import p7 from "../../../assets/images/portfolio/p-1/7.webp"

export default function P1() {
    const [img, setImg] = useState(p1)

    return (
        <div className="content-box">
            <div className="content-box-top">
                <div className="image-gallery">
                    <div className="big-img">
                        <img src={img} alt="" />
                    </div>
                    <div className="thumbs">
                        <img src={p1} alt="" onClick={()=> setImg(p1)}/>
                        <img src={p2} alt="" onClick={()=> setImg(p2)}/>
                        <img src={p3} alt="" onClick={()=> setImg(p3)}/>
                        <img src={p4} alt="" onClick={()=> setImg(p4)}/>
                        <img src={p5} alt="" onClick={()=> setImg(p5)}/>
                        <img src={p6} alt="" onClick={()=> setImg(p6)}/>
                        <img src={p7} alt="" onClick={()=> setImg(p7)}/>
                    </div>
                </div>

                <div className="details-box">
                    <div className="details-box-top">
                        <span>Website Name:</span>
                        <h2>Home6ers</h2>
                        <p>Plumbing and home renovation services providers in Delhi NCR area. 15 years of experience in this field to represent best renovation for luxury homes.</p>
                    </div>
                    <div className="details-box-tech pt-5">
                        <h3>Technology used:</h3>
                        <ul>
                            <li>Laravel</li>
                            <li>Vanila CSS</li>
                            <li>Jquery</li>
                            <li>Twak Two</li>
                        </ul>

                        <a href="https://home6ers.com/" target="_blank" className="btn btn-main btn-main-sm mt-5">Visit Website</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
