import React from 'react'
import { useState } from 'react'

import p1 from "../../../assets/images/portfolio/p-7/1.webp"
import p2 from "../../../assets/images/portfolio/p-7/2.webp"
import p3 from "../../../assets/images/portfolio/p-7/3.webp"
import p4 from "../../../assets/images/portfolio/p-7/4.webp"
import p5 from "../../../assets/images/portfolio/p-7/5.webp"
import p6 from "../../../assets/images/portfolio/p-7/6.webp"

export default function P7() {
    const [img, setImg] = useState(p1)

    return (
        <div className="content-box">
            <div className="content-box-top">
                <div className="image-gallery">
                    <div className="big-img">
                        <img src={img} alt="" />
                    </div>
                    <div className="thumbs">
                        <img src={p1} alt="" onClick={() => setImg(p1)} />
                        <img src={p2} alt="" onClick={() => setImg(p2)} />
                        <img src={p3} alt="" onClick={() => setImg(p3)} />
                        <img src={p4} alt="" onClick={() => setImg(p4)} />
                        <img src={p5} alt="" onClick={() => setImg(p5)} />
                        <img src={p6} alt="" onClick={() => setImg(p6)} />
                    </div>
                </div>

                <div className="details-box">
                    <div className="details-box-top">
                        <span>Website Name:</span>
                        <h2>Blayarson</h2>
                        <p>Ecommerce website for sale medicines and consultancy.</p>
                    </div>
                    <div className="details-box-tech pt-5">
                        <h3>Technology used:</h3>
                        <ul>
                            <li>Wordpress CMS</li>
                            <li>WooCommerce</li>
                            <li>Google Fonts</li>
                            <li>Animate.css</li>
                            <li>Font Awesome</li>
                            <li>Jquery</li>
                        </ul>

                        <a href="https://renterkart.com/" target="_blank" className="btn btn-main btn-main-sm mt-5">Visit Website</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
