import React, { useState, useCallback, useEffect } from "react";
import DocumentMeta from "react-document-meta";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import axios from "axios"

import p1 from "../../assets/images/portfolio/p-1/1.webp"
import p2 from "../../assets/images/portfolio/p-2/1.webp"
import p3 from "../../assets/images/portfolio/p-3/1.webp"
import p4 from "../../assets/images/portfolio/p-4/1.webp"
import p5 from "../../assets/images/portfolio/p-5/1.webp"
import p6 from "../../assets/images/portfolio/p-6/1.webp"
import p7 from "../../assets/images/portfolio/p-7/1.webp"
import x from "../../assets/images/x.svg"

import P1 from "./PortfolioContents/P1";
import P2 from "./PortfolioContents/P2";
import P3 from "./PortfolioContents/P3";
import P4 from "./PortfolioContents/P4";
import P5 from "./PortfolioContents/P5";
import P6 from "./PortfolioContents/P6";
import P7 from "./PortfolioContents/P7";

export default function Portfolio(props) {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [btn, setBtn] = useState("JOIN ME AS WELL");

    const meta = {
        title: "Website and Apps Development Portfolio - Techsell India",
        description: "Website and Apps Development Portfolio of UI design, UX design, website design, app screen design.",
        canonical: "https://techsellindia.com/portfolio",
        meta: {
            charset: "utf-8",
            name: {
                keywords: "react,meta,document,html,tags",
            },
        },
    };

    const onSubmit = (data) => {
        setBtn("Please wait...")
        axios.post("https://techsellindia.com/admin/api/contact_us", data).then((r) => {
            if (r.data.rcode == 200) {
                setBtn("MESSAGE SENT")
                setTimeout(() => {
                    setBtn("JOIN ME AS WELL")
                }, 2000);
            } else {
                setBtn("Something went wrong!")
            }
        })
    };

    const [showMainBox, setShowMainBox] = useState("")
    const [contentBox, setContentBox] = useState("")
    function viewDetails(e) {
        setShowMainBox("portfolio-big-box-show")
        setContentBox(e)
    }

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            setShowMainBox("")
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction);

        return () => {
            document.removeEventListener("keydown", escFunction);
        };
    }, [escFunction]);

    return (
        <DocumentMeta {...meta}>
            <div className="portfolio business-solutions-content digital-marketing-content pt-5">
                <div className="hero-image">
                    <div className="siteWidth">
                        <div className="hero-image-box">
                            <div className="hero-image-box-inner">
                                <div className="hero-image-box-left">
                                    <h1>
                                        Be a Part Of Our Family <span>START A DEVELOPMENT TODAY</span>
                                    </h1>
                                    <h3>BEST WEBSITE/APP DEVELOPMENT</h3>
                                    <p className="withbg">
                                        More then 100+ clients are growth thier business with our services in last 5 years.
                                    </p>
                                    <Link to="/contact" className="btn-main inline-block mt-5">
                                        Contact Us
                                    </Link>
                                </div>
                                <div className="hero-image-right">
                                    <h2>Contact Us</h2>
                                    <div className="hero-image-right-form">
                                        <form action="" onSubmit={handleSubmit(onSubmit)}>
                                            <input type="hidden" className="form-control" {...register("page_name")} defaultValue="Portfolio" />
                                            <input type="hidden" className="form-control" {...register("type")} defaultValue="Website Audit" />
                                            <input type="text" placeholder="Name" className="form-control" {...register("name", { required: true })} />
                                            <input type="number" placeholder="Phone" className="form-control" {...register("phone", { required: true })} />
                                            <input type="email" placeholder="Email" className="form-control" {...register("email", { required: true, pattern: /^\S+@\S+$/i })} />
                                            <input type="text" placeholder="Message" className="form-control" {...register("message", { required: true })} />
                                            <button>{btn}</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pt-16">
                    <div className="siteWidth">
                        <div className="portfolio-inner">
                            <div className="portfoli-box">
                                <div className="portfolio-img">
                                    <img src={p1} alt="" />
                                </div>
                                <div className="portfolio-text">
                                    <h2>Home6ers</h2>
                                    <p>Super plumbing services at local areas.</p>
                                    <div className="flex gap-3">
                                        <a href="https://home6ers.com/" target="_blank">Visit Site</a>
                                        <span onClick={() => viewDetails("p1")}>View Details</span>
                                    </div>
                                </div>
                            </div>

                            <div className="portfoli-box">
                                <div className="portfolio-img">
                                    <img src={p2} alt="" />
                                </div>
                                <div className="portfolio-text">
                                    <h2>Akshruthra Gold Trading</h2>
                                    <p>Akshruthra Gold &amp; Trading Company is the NO 1 Gold &amp; trading Company, (Akshruthra gold Pvt Ltd) certified company” established in the year 2021 running successfully from 1st January.</p>
                                    <div className="flex gap-3">
                                        <a href="https://akshruthragoldtrading.in/" target="_blank">Visit Site</a>
                                        <span onClick={() => viewDetails("p2")}>View Details</span>
                                    </div>
                                </div>
                            </div>

                            <div className="portfoli-box">
                                <div className="portfolio-img">
                                    <img src={p3} alt="" />
                                </div>
                                <div className="portfolio-text">
                                    <h2>Alivee</h2>
                                    <p>Amazing smooth E-Commerce website that made in Laravel PHP.</p>
                                    <div className="flex gap-3">
                                        <a href="https://alivee.in/" target="_blank">Visit Site</a>
                                        <span onClick={() => viewDetails("p3")}>View Details</span>
                                    </div>
                                </div>
                            </div>

                            <div className="portfoli-box">
                                <div className="portfolio-img">
                                    <img src={p4} alt="" />
                                </div>
                                <div className="portfolio-text">
                                    <h2>Anndata Samachar</h2>
                                    <p>An easy News portal for farmers in Hindi language.</p>
                                    <div className="flex gap-3">
                                        <a href="https://anndatasamachar.online/" target="_blank">Visit Site</a>
                                        <span onClick={() => viewDetails("p4")}>View Details</span>
                                    </div>
                                </div>
                            </div>

                            <div className="portfoli-box">
                                <div className="portfolio-img">
                                    <img src={p5} alt="" />
                                </div>
                                <div className="portfolio-text">
                                    <h2>Dayuse</h2>
                                    <p>Flexibility and personalization to hotel booking process.</p>
                                    <div className="flex gap-3">
                                        <a href="https://dayuse.in/" target="_blank">Visit Site</a>
                                        <span onClick={() => viewDetails("p5")}>View Details</span>
                                    </div>
                                </div>
                            </div>

                            <div className="portfoli-box">
                                <div className="portfolio-img">
                                    <img src={p6} alt="" />
                                </div>
                                <div className="portfolio-text">
                                    <h2>Renter Kart</h2>
                                    <p>Easy room and PG booking website.</p>
                                    <div className="flex gap-3">
                                        <a href="https://renterkart.com/" target="_blank">Visit Site</a>
                                        <span onClick={() => viewDetails("p6")}>View Details</span>
                                    </div>
                                </div>
                            </div>

                            <div className="portfoli-box">
                                <div className="portfolio-img">
                                    <img src={p7} alt="" />
                                </div>
                                <div className="portfolio-text">
                                    <h2>Blayarson</h2>
                                    <p>Ecommerce website for sale medicines and consultancy.</p>
                                    <div className="flex gap-3">
                                        <a href="https://blayarson.com/" target="_blank">Visit Site</a>
                                        <span onClick={() => viewDetails("p7")}>View Details</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* Big boxes */}

                        <div className={"portfolio-big-box " + showMainBox}>
                            <div className="portfolio-big-box-overlay" onClick={() => setShowMainBox("")}></div>
                            <div className="portfolio-big-box-inner">
                                <div className="close" onClick={() => setShowMainBox("")}><img src={x} /></div>
                                {contentBox === "p1" ? <P1 /> : contentBox === "p2" ? <P2 /> : contentBox === "p3" ? <P3 /> : contentBox === "p4" ? <P4 /> : contentBox === "p5" ? <P5 /> : contentBox === "p6" ? <P6 /> : contentBox === "p7" ? <P7 /> : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DocumentMeta>
    );
}
