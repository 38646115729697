import AboutMission from "../../components/about/AboutMission";
import AboutPageHeader from "../../components/about/AboutPageHeader";
import AboutTechsellStory from "../../components/about/AboutTechsellStory";
import AboutWhatWeOffer from "../../components/about/AboutWhatWeOffer";
import DocumentMeta from "react-document-meta";

export default function About() {
  const meta = {
    title: "Innovative Web Agency Helping Small and Medium Size Businesses",
    description:
      "Providing The Highest Quality Products and Services to Our Customers and Industries.",
    canonical: "https://techsellindia.com/about",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "react,meta,document,html,tags",
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="inner-page about-us-page">
        <AboutPageHeader />
        <AboutWhatWeOffer />
        <AboutTechsellStory />
        <AboutMission />
      </div>
    </DocumentMeta>
  );
}
