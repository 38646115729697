export default function AboutTechsellStory() {
  return (
    <div className="about-tech-story">
      <div className="siteWidth">
        <h2>The Story of Techsell India</h2>
        <p>
          We enjoy adapting our strategies to offer every client the best
          solutions that are at the forefront of the industry.
        </p>
        <div className="about-tech-cal">
          <div className="about-tech-cal-box">
            <div className="about-tech-cal-box-in">
              <h4 className="date">
                <span>2017</span>
              </h4>
              <p>
                A Breif History We joined the team and after much success, the
                agency was eventually.
              </p>
            </div>
          </div>
          <div className="about-tech-cal-box">
            <div className="about-tech-cal-box-in">
              <h4 className="date">
                <span>2018</span>
              </h4>
              <p>
                Something Big The team move into its first office, in the Noida
                UP
              </p>
            </div>
          </div>
          <div className="about-tech-cal-box">
            <div className="about-tech-cal-box-in">
              <h4 className="date">
                <span>2019</span>
              </h4>
              <p>
                The Army Grows The team of TECHSELL grow to around 10, as
                amazing new clients come on board.
              </p>
            </div>
          </div>

          <div className="about-tech-cal-box">
            <div className="about-tech-cal-box-in">
              <h4 className="date">
                <span>2020</span>
              </h4>
              <p>
                An Award-Winning Creation Continued growth in cool new clients
                means that our team continues.
              </p>
            </div>
          </div>

          <div className="about-tech-cal-box">
            <div className="about-tech-cal-box-in">
              <h4 className="date">
                <span>2021</span>
              </h4>
              <p>
                Due to covid it was harsh year for the world. But our clients shows trust on us and we did our best for them.
              </p>
            </div>
          </div>

          <div className="about-tech-cal-box">
            <div className="about-tech-cal-box-in">
              <h4 className="date">
                <span>2022</span>
              </h4>
              <p>
                Growth the counts of out clients those shows trust on us for their business.
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
