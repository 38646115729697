import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faPlaneDeparture,
  faCarrot,
  faLaptopCode,
  faChartLine,
  faSitemap,
  faCubes,
  faServer,
} from "@fortawesome/free-solid-svg-icons";
import img1 from "../../assets/images/web-development/image-1.webp"

const cart = <FontAwesomeIcon icon={faShoppingCart} />;
const plan = <FontAwesomeIcon icon={faPlaneDeparture} />;
const grocery = <FontAwesomeIcon icon={faCarrot} />;
const ed = <FontAwesomeIcon icon={faLaptopCode} />;
const digital = <FontAwesomeIcon icon={faChartLine} />;
const it = <FontAwesomeIcon icon={faSitemap} />;
const crm = <FontAwesomeIcon icon={faCubes} />;
const portal = <FontAwesomeIcon icon={faServer} />;

export default function BusinessSolutions() {
  return (
    <div className="home-business">
      <div className="siteWidth">
        <div className="home-business-box">
          <div className="home-business-box-right">
            <img
              src={img1}
              alt="business solutions"
            />
          </div>
          <div className="home-business-box-left">
            <h2 className="border-hd">Business Solutions</h2>
            <p>
              Techsell is an award-winning consultancy which uses IT solutions
              to solve complex business challenges.
            </p>
            <div className="home-business-services">
              <div className="hb_service">
                <div className="hb_service-in">
                  <span className="hb_icon">{cart}</span>
                  <p>E-Commerce Solution</p>
                </div>
              </div>

              <div className="hb_service">
                <div className="hb_service-in">
                  <span className="hb_icon">{plan}</span>
                  <p>Travel Solution</p>
                </div>
              </div>

              <div className="hb_service">
                <div className="hb_service-in">
                  <span className="hb_icon">{grocery}</span>
                  <p>Grocery Solutions</p>
                </div>
              </div>
              <div className="hb_service">
                <div className="hb_service-in">
                  <span className="hb_icon">{ed}</span>
                  <p>ED TECH Solution</p>
                </div>
              </div>
              <div className="hb_service">
                <div className="hb_service-in">
                  <span className="hb_icon">{digital}</span>
                  <p>Digital Media Solution</p>
                </div>
              </div>
              <div className="hb_service">
                <div className="hb_service-in">
                  <span className="hb_icon">{it}</span>
                  <p>IT Operation Management</p>
                </div>
              </div>
              <div className="hb_service">
                <div className="hb_service-in">
                  <span className="hb_icon">{crm}</span>
                  <p>CRM/ERP</p>
                </div>
              </div>
              <div className="hb_service">
                <div className="hb_service-in">
                  <span className="hb_icon">{portal}</span>
                  <p>Portal Solution</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
