import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCode,
  faPrint,
  faShoppingBag,
  faTabletAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faAndroid,
  faApple,
  faLaravel,
  faReact,
} from "@fortawesome/free-brands-svg-icons";
import img1 from "../../assets/images/mobile-app/mobile-1.webp"

export default function DesignDevelopment() {
  return (
    <div className="home-design-dev">
      <div className="siteWidth">
        <div className="home-design-dev-left">
          <img
            src={img1}
            alt="business solutions"
          />
        </div>
        <div className="home-design-dev-right">
          <h2 className="border-hd">Design &amp; Development</h2>
          <p>
            Repeated business model doesn't work these days, unless you have an
            internet wing to tap on the power of online customers. Welcome to
            Techsell India we offer a range of web development services,
            designed to cater across all niches and industries.
          </p>
          <div className="home-business-services">
            <div className="hb_service">
              <div className="hb_service-in">
                <span className="hb_icon">
                  <FontAwesomeIcon icon={faTabletAlt} />
                </span>
                <p>Responsive design</p>
              </div>
            </div>

            <div className="hb_service">
              <div className="hb_service-in">
                <span className="hb_icon">
                  <FontAwesomeIcon icon={faReact} />
                </span>
                <p>React web development</p>
              </div>
            </div>

            <div className="hb_service">
              <div className="hb_service-in">
                <span className="hb_icon">
                  <FontAwesomeIcon icon={faAndroid} />
                </span>
                <p>Android apps development</p>
              </div>
            </div>
            <div className="hb_service">
              <div className="hb_service-in">
                <span className="hb_icon">
                  <FontAwesomeIcon icon={faLaravel} />
                </span>
                <p>Laravel web development</p>
              </div>
            </div>
            <div className="hb_service">
              <div className="hb_service-in">
                <span className="hb_icon">
                  <FontAwesomeIcon icon={faApple} />
                </span>
                <p>iOS apps development</p>
              </div>
            </div>
            <div className="hb_service">
              <div className="hb_service-in">
                <span className="hb_icon">
                  <FontAwesomeIcon icon={faCode} />
                </span>
                <p>UX/UI design</p>
              </div>
            </div>
            <div className="hb_service">
              <div className="hb_service-in">
                <span className="hb_icon">
                  <FontAwesomeIcon icon={faShoppingBag} />
                </span>
                <p>E-commerce developmen</p>
              </div>
            </div>
            <div className="hb_service">
              <div className="hb_service-in">
                <span className="hb_icon">
                  <FontAwesomeIcon icon={faPrint} />
                </span>
                <p>Print ready design</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
