import React, { useState } from "react";
import DocumentMeta from "react-document-meta";
import topImage1 from "../../assets/images/business-solution/fe-1-4.png";
import topImage2 from "../../assets/images/business-solution/fe-1-5.png";
import topImage3 from "../../assets/images/business-solution/fe-1-6.png";
import topImage4 from "../../assets/images/business-solution/fe-1-7.png";
import topImage5 from "../../assets/images/business-solution/fe-1-8.png";
import topImage6 from "../../assets/images/business-solution/fe-1-9.png";
import digimg from "../../assets/images/business-solution/digital-marketing.webp";
import p1 from "../../assets/images/digital/p-1.jpg";
import p2 from "../../assets/images/digital/p-2.jpg";
import p3 from "../../assets/images/digital/p-3.jpg";
import p4 from "../../assets/images/digital/p-4.jpg";
import axios from "axios"
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  ExpandMore,
  Fitbit,
  CheckCircle,
  Cancel
} from "@mui/icons-material";

export default function DigitalMarketing(props) {
  const { register, handleSubmit } = useForm();
  const [btn, setBtn] = useState("SEND ME WEBSITE AUDIT");

  const meta = {
    title: "Digital marketing Agency in USA - Techsell India",
    description: "Best Digital marketing agency in USA with certified digital marketing experts for all Online Marketing Services, SEO, Social Media Marketing, SMO, SEM",
    canonical: "https://techsellindia.com/digital-marketing",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "Digital Marketing Agency, Digital Marketing company in usa, #1 seo services USA, SMO services, Social media marketing, Seaech engine marketing, Digital Marketing agency in USA",
      },
    },
  };

  const onSubmit = (data) => {
    setBtn("Please wait...")
    axios.post("https://techsellindia.com/admin/api/contact_us", data).then((r) => {
      if (r.data.rcode === 200) {
        setBtn("MESSAGE SENT")
        setTimeout(() => {
          setBtn("SEND ME WEBSITE AUDIT")
        }, 2000);
      } else {
        setBtn("Something went wrong!")
      }
    })
  };


  const onSubmitb = (data) => {
    setBtn("Please wait...")
    axios.post("https://techsellindia.com/admin/api/contact_us", data).then((r) => {
      if (r.data.rcode === 200) {
        setBtn("MESSAGE SENT")
        setTimeout(() => {
          setBtn("SEND ME WEBSITE AUDIT")
        }, 2000);
      } else {
        setBtn("Something went wrong!")
      }
    })
  };

  const [expandedPanel, setExpandedPanel] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    console.log({ event, isExpanded });
    setExpandedPanel(isExpanded ? panel : false);
  };

  return (
    <DocumentMeta {...meta}>
      <div className="business-solutions pt-4">
        <div className="business-solutions-content digital-marketing-content">
          <div className="hero-image">
            <div className="siteWidth">
              <div className="hero-image-box">
                <div className="hero-image-box-inner">
                  <div className="hero-image-box-left">
                    <h1>
                      Want Business Growth? <span>WE HAVE TOP MARKETERS</span>
                    </h1>
                    <h3>DEGITAL MARKETING SOLUTION 2022</h3>
                    <p className="withbg">
                      More then 100+ clients are groth thier business with our SEO service in last 5 years.
                    </p>
                    <Link to="/contact" className="btn-main inline-block mt-5">
                      Contact Us
                    </Link>
                  </div>
                  <div className="hero-image-right">
                    <h2>Get a Free Audit</h2>
                    <div className="hero-image-right-form">
                      <form action="" onSubmit={handleSubmit(onSubmit)}>
                        <input type="hidden" className="form-control" {...register("page_name")} defaultValue="Digital Marketing" />
                        <input type="hidden" className="form-control" {...register("type")} defaultValue="Website Audit" />
                        <input type="text" placeholder="Name" className="form-control" {...register("name", { required: true })} />
                        <input type="number" placeholder="Phone" className="form-control" {...register("phone", { required: true })} />
                        <input type="email" placeholder="Email" className="form-control" {...register("email", { required: true, pattern: /^\S+@\S+$/i })} />
                        <input type="text" placeholder="Werbsite URL" className="form-control" {...register("message", { required: true })} />
                        <button>{btn}</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sitecontent business-solutions-content-main">
            <div className="siteWidth">
              <h2>Welcome to Search engine Optimization services of Techsell India</h2>
              <p>Our company deals with great SEO policies, make them possible for your business to make your business grow digitally and make large online rankings for your products. The SEO professionals have been doing great work in making your business search online by your target audience. We have the techniques and power to deal with any website and solve the problem related to it. We the professionals are not just bothered about the traffic but also ensure that only the targeted traffic can be required for more conversions.</p>

              <p>The Search Engine Optimization had always been a main part in digital marketing so our professionals try to get more and more conversions and make your product promote all over the world.</p>
            </div>
          </div>

          <div className="seo-package-box">
            <div className="siteWidth">
              <div id="tablist" className="seo-package-tab">
                <Tabs>
                  <div >
                    <TabList>
                      <div className="seo-package-tab-inner">
                        <div className="left"></div>
                        <div className="right">
                          <Tab>MONTHLY PACKAGES</Tab>
                          <Tab>QUARTERLY PACKAGES</Tab>
                          <Tab>HALF YEARLY PACKAGES</Tab>
                          <Tab>YEARLY PACKAGES</Tab>
                        </div>
                      </div>
                    </TabList>
                  </div>

                  <TabPanel>
                    <div className="tab-content">
                      <div className="left">
                        <img src={p1} alt="SEO Package" />
                      </div>
                      <div className="right">
                        <div className="digi-price-tag price-1">
                          <p>VALUE</p>
                          <h4>$229</h4>
                          <Link to="/contact">
                            <span>Buy Now</span>
                          </Link>
                        </div>

                        <div className="digi-price-tag price-2">
                          <p>BRONZE</p>
                          <h4>$329</h4>
                          <Link to="/contact">
                            <span>Buy Now</span>
                          </Link>
                        </div>

                        <div className="digi-price-tag price-3">
                          <p>SILVER</p>
                          <h4>$429</h4>
                          <Link to="/contact">
                            <span>Buy Now</span>
                          </Link>
                        </div>

                        <div className="digi-price-tag price-4">
                          <p>GOLD</p>
                          <h4>$529</h4>
                          <Link to="/contact">
                            <span>Buy Now</span>
                          </Link>
                        </div>

                        <div className="digi-price-tag price-5">
                          <p>PLATINUM</p>
                          <h4>$829</h4>
                          <Link to="/contact">
                            <span>Buy Now</span>
                          </Link>
                        </div>

                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="tab-content">
                      <div className="left">
                        <img src={p2} alt="Affordable SEO Package" />
                      </div>
                      <div className="right">

                        <div className="digi-price-tag price-1">
                          <p>VALUE</p>
                          <h4>$549</h4>
                          <Link to="/contact">
                            <span>Buy Now</span>
                          </Link>
                        </div>

                        <div className="digi-price-tag price-2">
                          <p>BRONZE</p>
                          <h4>$749</h4>
                          <Link to="/contact">
                            <span>Buy Now</span>
                          </Link>
                        </div>

                        <div className="digi-price-tag price-3">
                          <p>SILVER</p>
                          <h4>$1149</h4>
                          <Link to="/contact">
                            <span>Buy Now</span>
                          </Link>
                        </div>

                        <div className="digi-price-tag price-4">
                          <p>GOLD</p>
                          <h4>$1299</h4>
                          <Link to="/contact">
                            <span>Buy Now</span>
                          </Link>
                        </div>

                        <div className="digi-price-tag price-5">
                          <p>PLATINUM</p>
                          <h4>$1499</h4>
                          <Link to="/contact">
                            <span>Buy Now</span>
                          </Link>
                        </div>

                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="tab-content">
                      <div className="left">
                        <img src={p3} alt="Best SEO Package" />
                      </div>
                      <div className="right">

                        <div className="digi-price-tag price-1">
                          <p>VALUE</p>
                          <h4>$899</h4>
                          <Link to="/contact">
                            <span>Buy Now</span>
                          </Link>
                        </div>

                        <div className="digi-price-tag price-2">
                          <p>BRONZE</p>
                          <h4>$1299</h4>
                          <Link to="/contact">
                            <span>Buy Now</span>
                          </Link>
                        </div>

                        <div className="digi-price-tag price-3">
                          <p>SILVER</p>
                          <h4>$1999</h4>
                          <Link to="/contact">
                            <span>Buy Now</span>
                          </Link>
                        </div>

                        <div className="digi-price-tag price-4">
                          <p>GOLD</p>
                          <h4>$2499</h4>
                          <Link to="/contact">
                            <span>Buy Now</span>
                          </Link>
                        </div>

                        <div className="digi-price-tag price-5">
                          <p>PLATINUM</p>
                          <h4>$2799</h4>
                          <Link to="/contact">
                            <span>Buy Now</span>
                          </Link>
                        </div>

                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="tab-content">
                      <div className="left">
                        <img src={p4} alt="Best SEO Package" />
                      </div>
                      <div className="right">

                        <div className="digi-price-tag price-1">
                          <p>VALUE</p>
                          <h4>$1299</h4>
                          <Link to="/contact">
                            <span>Buy Now</span>
                          </Link>
                        </div>

                        <div className="digi-price-tag price-2">
                          <p>BRONZE</p>
                          <h4>$1999</h4>
                          <Link to="/contact">
                            <span>Buy Now</span>
                          </Link>
                        </div>

                        <div className="digi-price-tag price-3">
                          <p>SILVER</p>
                          <h4>$3499</h4>
                          <Link to="/contact">
                            <span>Buy Now</span>
                          </Link>
                        </div>

                        <div className="digi-price-tag price-4">
                          <p>GOLD</p>
                          <h4>$4799</h4>
                          <Link to="/contact">
                            <span>Buy Now</span>
                          </Link>
                        </div>

                        <div className="digi-price-tag price-5">
                          <p>PLATINUM</p>
                          <h4>$8999</h4>
                          <Link to="/contact">
                            <span>Buy Now</span>
                          </Link>
                        </div>

                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
              <div className="seo-packages-list">
                <div className="seo-packages-list-box">
                  <h3>WEBSITE ATTRIBUTES</h3>
                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Number of Keywords</div>
                    <div className="box-2">1 - 10 Keywords</div>
                    <div className="box-2">11 - 20 Keywords</div>
                    <div className="box-2">21 - 40 Keywords</div>
                    <div className="box-2">41 - 60 Keywords</div>
                    <div className="box-2">61 - 150 Keywords</div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> No. of Keywords Guaranteed in Top 10</div>
                    <div className="box-2">7</div>
                    <div className="box-2">15</div>
                    <div className="box-2">32</div>
                    <div className="box-2">48</div>
                    <div className="box-2">125</div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Delivery Time Frame</div>
                    <div className="box-2">1 - 3 Months</div>
                    <div className="box-2">3 - 6 Months</div>
                    <div className="box-2">3 - 6 Months</div>
                    <div className="box-2">4 - 8 Months</div>
                    <div className="box-2">5 - 12 Months</div>
                  </div>
                </div>

                <div className="seo-packages-list-box">
                  <h3>RESEARCH &amp; ANALYSIS</h3>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Keywords research</div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span> </div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Competitive Analysis</div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span> </div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Panda or Penguin Testing</div>
                    <div className="box-2"><span className="text-red-600"><Cancel /></span> </div>
                    <div className="box-2"><span className="text-red-600"><Cancel /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Google Analytics Setup</div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Google Webmaster Setup</div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>
                </div>

                <div className="seo-packages-list-box">
                  <h3>ON-PAGE OPTIMIZATIONS</h3>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Title Tags and Meta Tags Optimization</div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span> </div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Website Structure Optimization</div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span> </div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> SEO Friendly URLs ( URL Rewriting ) *</div>
                    <div className="box-2"><span className="text-red-600"><Cancel /></span> </div>
                    <div className="box-2"><span className="text-red-600"><Cancel /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> W3C Validation *</div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Keyword Density In Site Content
                    </div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Existing Content Optimization
                    </div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Optimization of HTML Source Code
                    </div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Optimization of CSS Source Code
                    </div>
                    <div className="box-2"><span className="text-red-600"><Cancel /></span></div>
                    <div className="box-2"><span className="text-red-600"><Cancel /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Robots.txt Optimization
                    </div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Xml-sitemap file Creation &amp; Updations
                    </div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Image Optimization
                    </div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Anchor Tag Optimization
                    </div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Heading Tags
                    </div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Creation of new Optimized Pages
                    </div>
                    <div className="box-2"><span className="text-red-600"><Cancel /></span></div>
                    <div className="box-2"><span className="text-red-600"><Cancel /></span></div>
                    <div className="box-2">(if required)</div>
                    <div className="box-2">(if required)</div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Broken Links
                    </div>
                    <div className="box-2">Recommend only</div>
                    <div className="box-2">Recommend only</div>
                    <div className="box-2">Fix all Broken</div>
                    <div className="box-2">Fix all Broken</div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Canonicalization Error Check</div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Blog Integration</div>
                    <div className="box-2"><span className="text-red-600"><Cancel /></span></div>
                    <div className="box-2"><span className="text-red-600"><Cancel /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>


                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> URL Mapping</div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                </div>

                <div className="seo-packages-list-box">
                  <h3>OFF-PAGE OPTIMIZATIONS</h3>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Manual Directory Submission</div>
                    <div className="box-2">25</div>
                    <div className="box-2">50</div>
                    <div className="box-2">75</div>
                    <div className="box-2">100</div>
                    <div className="box-2">150 - 200</div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Social Bookmarking</div>
                    <div className="box-2">20</div>
                    <div className="box-2">40</div>
                    <div className="box-2">60</div>
                    <div className="box-2">80</div>
                    <div className="box-2">80</div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Social Bookmarking</div>
                    <div className="box-2">6 with 2 unique content</div>
                    <div className="box-2">9 with 3 unique content</div>
                    <div className="box-2">12 with 4 unique content</div>
                    <div className="box-2">15 with 5 unique content</div>
                    <div className="box-2">30 with 6 unique content</div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Article Promotion</div>
                    <div className="box-2">10</div>
                    <div className="box-2">20</div>
                    <div className="box-2">30</div>
                    <div className="box-2">40</div>
                    <div className="box-2">60</div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Press Release Submission</div>
                    <div className="box-2">4 with 1 unique content</div>
                    <div className="box-2">8 with 2 unique content</div>
                    <div className="box-2">12 with 3 unique content</div>
                    <div className="box-2">16 with 4 unique content</div>
                    <div className="box-2">24 with 6 unique content</div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Local Classified Ad posting</div>
                    <div className="box-2">5</div>
                    <div className="box-2">10</div>
                    <div className="box-2">15</div>
                    <div className="box-2">30-40</div>
                    <div className="box-2">40</div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Search Engine Submission
                    </div>
                    <div className="box-2">5</div>
                    <div className="box-2">10</div>
                    <div className="box-2">15</div>
                    <div className="box-2">30</div>
                    <div className="box-2">40</div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Blog Directory Submission
                    </div>
                    <div className="box-2">5</div>
                    <div className="box-2">5</div>
                    <div className="box-2">10</div>
                    <div className="box-2">10</div>
                    <div className="box-2">20</div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Forums Profle Creation, posting &amp; Commenting
                    </div>
                    <div className="box-2"><span className="text-red-600"><Cancel /></span></div>
                    <div className="box-2"><span className="text-red-600"><Cancel /></span></div>
                    <div className="box-2">5</div>
                    <div className="box-2">10</div>
                    <div className="box-2">15</div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Social Network Profile &amp; update
                    </div>
                    <div className="box-2">4</div>
                    <div className="box-2">6</div>
                    <div className="box-2">8</div>
                    <div className="box-2">10</div>
                    <div className="box-2">15</div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> PPT Creation and Submission
                    </div>
                    <div className="box-2">1 *5</div>
                    <div className="box-2">1 *5</div>
                    <div className="box-2">2 *10</div>
                    <div className="box-2">3 *15</div>
                    <div className="box-2">5 *25</div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> PPT Promotions
                    </div>
                    <div className="box-2">5</div>
                    <div className="box-2">5</div>
                    <div className="box-2">10</div>
                    <div className="box-2">15</div>
                    <div className="box-2">25</div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Business Directory Submissions
                    </div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                </div>

                <div className="seo-packages-list-box">
                  <h3>LOCAL MARKETING (ONE TIME ACTIVITY)</h3>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Google Local Business Listing Setup
                    </div>
                    <div className="box-2"><span className="text-red-600"><Cancel /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Google Local Business Listing Optimization
                    </div>
                    <div className="box-2"><span className="text-red-600"><Cancel /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Google Map Integration
                    </div>
                    <div className="box-2"><span className="text-red-600"><Cancel /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Business Listings Submission &amp; Reviews (Monthly)
                    </div>
                    <div className="box-2"><span className="text-red-600"><Cancel /></span></div>
                    <div className="box-2">2</div>
                    <div className="box-2">3</div>
                    <div className="box-2">5</div>
                    <div className="box-2">10</div>
                  </div>

                </div>

                <div className="seo-packages-list-box">
                  <h3>CONTENT MARKETING AND BLOG OPTIMIZATION</h3>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Blog Creation</div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Unique content posting on Web 2.0 sites
                    </div>
                    <div className="box-2">2</div>
                    <div className="box-2">4</div>
                    <div className="box-2">6</div>
                    <div className="box-2">8</div>
                    <div className="box-2">10</div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Blog Promotions
                    </div>
                    <div className="box-2">15</div>
                    <div className="box-2">25</div>
                    <div className="box-2">35</div>
                    <div className="box-2">45</div>
                    <div className="box-2">50</div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Blog Pinging
                    </div>
                    <div className="box-2">5</div>
                    <div className="box-2">10</div>
                    <div className="box-2">15</div>
                    <div className="box-2">20</div>
                    <div className="box-2">20</div>
                  </div>
                </div>

                <div className="seo-packages-list-box">
                  <h3>SOCIAL MEDIA OPTIMIZATIONS</h3>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Facebook Page creation &amp; Update</div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Google Plus Page Creation &amp; Promotions</div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Twitter Account Creation, update &amp; followers</div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Pinterest Profile Setup &amp; Promotions</div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                </div>

                <div className="seo-packages-list-box">
                  <h3>MEDIA OPTIMIZATIONS</h3>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Youtube Account Setup &amp; Update</div>
                    <div className="box-2"><span className="text-red-600"><Cancel /></span></div>
                    <div className="box-2">Setup &amp; Updates</div>
                    <div className="box-2">Setup, Updates &amp; Promotions</div>
                    <div className="box-2">Setup, Updates &amp; Promotions</div>
                    <div className="box-2">Setup, Updates &amp; Promotions</div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> YouTube Channel Creation</div>
                    <div className="box-2"><span className="text-red-600"><Cancel /></span></div>
                    <div className="box-2"><span className="text-red-600"><Cancel /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Video Promotions</div>
                    <div className="box-2"><span className="text-red-600"><Cancel /></span></div>
                    <div className="box-2"><span className="text-red-600"><Cancel /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Other Video Creation &amp; Promotions</div>
                    <div className="box-2"><span className="text-red-600"><Cancel /></span></div>
                    <div className="box-2"><span className="text-red-600"><Cancel /></span></div>
                    <div className="box-2"><span className="text-red-600"><Cancel /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                </div>

                <div className="seo-packages-list-box">
                  <h3>REPORTING</h3>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Weekly Report</div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Monthly Activity Report</div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Monthly Keywords Ranking Report</div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Traffic Report </div>
                    <div className="box-2"><span className="text-red-600"><Cancel /></span></div>
                    <div className="box-2">If required</div>
                    <div className="box-2"><span className="text-red-600"><Cancel /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                    <div className="box-2"><span className="text-green-600"><CheckCircle /></span></div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Monthly Backlink</div>
                    <div className="box-2">60</div>
                    <div className="box-2">90</div>
                    <div className="box-2">125</div>
                    <div className="box-2">200</div>
                    <div className="box-2">200</div>
                  </div>
                </div>

                <div className="seo-packages-list-box">
                  <h3>SETUP FEE</h3>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"><span className="icon"><Fitbit /></span> Monthly Backlink</div>
                    <div className="box-2">Free</div>
                    <div className="box-2">Free</div>
                    <div className="box-2">Free</div>
                    <div className="box-2">Free</div>
                    <div className="box-2">Free</div>
                  </div>

                  <div className="seo-packages-list-box-inner">
                    <div className="box-1"></div>
                    <div className="box-2"><Link to="/contact" className="btn btn-main btn-main-sm">Contact Us</Link></div>
                    <div className="box-2"><Link to="/contact" className="btn btn-main btn-main-sm">Contact Us</Link></div>
                    <div className="box-2"><Link to="/contact" className="btn btn-main btn-main-sm">Contact Us</Link></div>
                    <div className="box-2"><Link to="/contact" className="btn btn-main btn-main-sm">Contact Us</Link></div>
                    <div className="box-2"><Link to="/contact" className="btn btn-main btn-main-sm">Contact Us</Link></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="business-development-top">
            <div className="siteWidth">
              <div className="business-development-top-boxes">
                <div className="box-inner">
                  <img src={topImage1} alt="" />
                  <h2>Incrase Brand Popularity</h2>
                  <p>
                    Brand Awareness has led to replacing common words with the brand name.
                    We focus to familiar your target audience is with your brand.
                  </p>
                </div>

                <div className="box-inner">
                  <img src={topImage2} alt="" />
                  <h2>Local Business Listing</h2>
                  <p>
                    Catch the attention of local customers in order to make an improvement in their sales, products and services.
                  </p>
                </div>

                <div className="box-inner">
                  <img src={topImage3} alt="" />
                  <h2>Ranking in Top 10</h2>
                  <p>
                    Business visiblity under the top 10 list at the first page of the Google/Yahoo/Bing search engines.
                  </p>
                </div>


              </div>

              <div className="business-development-top-boxes">
                <div className="box-inner">
                  <img src={topImage4} alt="" />
                  <h2>Increment In Sales</h2>
                  <p>
                    Are you running an E-Commerce website? It's time to increase sales of your online business. Get more customer engagements and more sales.
                  </p>
                </div>

                <div className="box-inner">
                  <img src={topImage5} alt="" />
                  <h2>Leads Generation</h2>
                  <p>
                    The campaigns used for generating leads are conceptualised by our team who implements them to gain validated leads.
                  </p>
                </div>

                <div className="box-inner">
                  <img src={topImage6} alt="" />
                  <h2>Google Friendly Website</h2>
                  <p>
                    Now days this is highly reaccomended for websites should be completly Google and User friendly. We can create and update exited website that easy to Google.
                  </p>
                </div>


              </div>


            </div>
          </div>

          <div className="sitecontent py-10 mt-8 bg-indigo-50">
            <div className="siteWidth">
              <div className="box-x-2 flex gap-7 items-center">
                <div className="box-x-2-left w-4/5">
                  <h2 className="pb-2">Why Digital Marketing Required For Your Business?</h2>
                  <p>The digital marketing is a part of marketing where sellers sell their product online by giving the promotion of the product over digital media. Nowadays, the digital marketing has been expanded globally, so each and every company depends upon digital marketing. So, our company mainly focuses on the criteria that how a company's products should get promoted in the digital media. The digital marketing which utilizes internet and online based digital technologies like desktop, smart phones, computers etc.</p>
                </div>
                <div className="box-x-2-right">
                  <img src={digimg} alt="" width={450} />
                </div>
              </div>
              <div className="box-x-3 pt-5">
                <ul className="columns-3">
                  <li>100% White Hat SEO</li>
                  <li>Affordable SEO Packages</li>
                  <li>In-house SEO Team</li>
                  <li>100% Transparency</li>
                  <li>Proven Track Record</li>
                  <li>Trusted Google Partner</li>
                  <li>Build Brand Reputation</li>
                  <li>Improved Conversion</li>
                  <li>Better Return on investment</li>
                  <li>Improve Global Reach</li>
                  <li>Target the Right Audience</li>
                  <li>White Label SEO Reseller Program</li>
                  <li>95% Client Retention</li>
                  <li>Assured SEO Results</li>
                  <li>24/7 Access to Team</li>
                  <li>On-Time Monthly Reporting</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="sitecontent py-10 bg-indigo-400">
            <div className="siteWidth">
              <div className="mid-form">
                <h3 className="text-center text-white">Get a Free Audit</h3>
                <form action="" onSubmit={handleSubmit(onSubmitb)}>
                  <input type="hidden" className="form-control" {...register("page_name")} defaultValue="Digital Marketing" />
                  <input type="hidden" className="form-control" {...register("type")} defaultValue="Website Audit" />
                  <div className="md:flex gap-3 justify-center">
                    <div className="form-group">
                      <input type="text" placeholder="Name" className="form-control" {...register("name", { required: true })} />
                    </div>
                    <div className="form-group">
                      <input type="number" placeholder="Phone" className="form-control" {...register("phone", { required: true })} />
                    </div>
                    <div className="form-group">
                      <input type="email" placeholder="Email" className="form-control" {...register("email", { required: true })} />
                    </div>
                    <div className="form-group">
                      <input type="text" placeholder="Werbsite URL" className="form-control" {...register("message", { required: true })} />
                    </div>
                  </div>
                  <div className="text-center">
                    <button className="btn btn-main">{btn}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="sitecontent business-solutions-content-main mt-5">
            <div className="siteWidth">
              <h3 className="pb-3">
                Our SEO Process includes the following:
              </h3>
              <Accordion
                expanded={expandedPanel === "panel1"}
                onChange={handleAccordionChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  className="plan-box silver-plan"
                >
                  <b>Auditing the current website:</b>
                </AccordionSummary>

                <AccordionDetails>
                  <p>The website which has been developed by someone but needs to be improve by giving some SEO criteria. Our company audits a website and make the website better by explaining SEO criterion like site speed, image optimization, content writing, title tags etc. The SEO professionals make your website more productive so that you can promote your products easily.</p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expandedPanel === "panel2"}
                onChange={handleAccordionChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  className="plan-box silver-plan"
                >
                  <b>Keywords Research:</b>
                </AccordionSummary>

                <AccordionDetails>
                  <p>The keywords are most important while developing a website because the customers would come to your website only when the keywords are appropriate to them. We develop the best keywords for your website so that your website will always be seen in the very first in the search results.</p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expandedPanel === "panel3"}
                onChange={handleAccordionChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  className="plan-box silver-plan"
                >
                  <b>Link Building:</b>
                </AccordionSummary>

                <AccordionDetails>
                  <p>The link building is always been an important part in the process of SEO because the link building is the main concept in which the website can easily connect to other big websites and can get website traffic from it. Our company Techsell India always tries to build the link of the website from the other bigger website so that website could reach in heights at resulted search engines and customers will always prefer your website whenever they want something from your website.</p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expandedPanel === "panel4"}
                onChange={handleAccordionChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  className="plan-box silver-plan"
                >
                  <b>Competitor Analysis:</b>
                </AccordionSummary>

                <AccordionDetails>
                  <p>We should always be able to get the analysis of competitor because the competitors are the only source from which we get motivated and do our work far better from them. Our company analyse your competitors compare them with your company and make necessary and productive website so that customers get attracted to it and take products and services from you.</p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expandedPanel === "panel5"}
                onChange={handleAccordionChange("panel5")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  className="plan-box silver-plan"
                >
                  <b>Strategizing the new SEO plan:</b>
                </AccordionSummary>

                <AccordionDetails>
                  <p>Our company Techsell India has been in a top in planning of seo and how to strategize it in the website. As it is a free of cost the company can opt for advance SEO to make your website as a top search in the internet. The SEO plan can really make your website productive and make your company grow vast and secure to customers.</p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expandedPanel === "panel6"}
                onChange={handleAccordionChange("panel6")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  className="plan-box silver-plan"
                >
                  <b>Content check:</b>
                </AccordionSummary>

                <AccordionDetails>
                  <p>The content check is the main part in the website because the customers only see the content how much it is catchy to them and it will also make your website at a highest in search results. Our company will help your website by giving content that would be required to attract and make your website a better one in the eyes of the customers.</p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expandedPanel === "panel7"}
                onChange={handleAccordionChange("panel7")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  className="plan-box silver-plan"
                >
                  <b>On-Page optimization:</b>
                </AccordionSummary>

                <AccordionDetails>
                  <p>The on-page optimization is something where the websites are getting optimized to help the search engines better understand your website. The on-page optimization helps the website to know whether it is useful for the customers or not. Our top professionals have been doing their work for optimizing the website and make the website better than before.</p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expandedPanel === "panel8"}
                onChange={handleAccordionChange("panel8")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  className="plan-box silver-plan"
                >
                  <b>Off-Page optimization:</b>
                </AccordionSummary>

                <AccordionDetails>
                  <p>The off-page optimization is a technique which refers to improve the position of a website in the search result page. As off-page optimization makes up to 75% of the good marketing strategy it is very essential to opt an off-page to your website. As Techsell India has best SEO professionals they help your website to be in a better position so that the customer will also see your website and you will also get profit relating to it.</p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expandedPanel === "panel9"}
                onChange={handleAccordionChange("panel9")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  className="plan-box silver-plan"
                >
                  <b>Social Media Marketing:</b>
                </AccordionSummary>

                <AccordionDetails>
                  <p>In this late digital era, the digital marketing is important but in this the most important concept is social media marketing where the company can promote their products in various platforms like Instagram, Facebook where they can get their potential customers and they also get their profit through it. Our company will help promoting your products with advanced technology so that the products would sell and the company can grow even more faster than before.</p>
                </AccordionDetails>
              </Accordion>

            </div>
          </div>

        </div>
      </div>
    </DocumentMeta>
  );
}
