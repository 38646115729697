export default function AboutPageHeader() {
  return (
    <div className="about-us-page-header">
      <div className="siteWidth">
        <h1>About Techsell India culture</h1>
        <p>
          Techsell is an innovative web agency based in India. Our passion for
          helping small and medium size businesses has grown us into a
          full-service strategic marketing company developing online solutions
          for organizations across business sectors.
          <br />
          <br />
          Our mission is always to provide the highest quality products and
          services to our customers. This customer-focused mindset has earned us
          accolades for offering some of the best service and support in the
          industry. We build mutually beneficial relationships with our clients
          that deliver success.
        </p>
      </div>
    </div>
  );
}
