import React from "react";
import DocumentMeta from "react-document-meta";

export default function ReturnRefund() {
  const meta = {
    title: "Return and Refund",
    description: "Return and Refund",
    canonical: "https://techsellindia.com/return-refund",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "react,meta,document,html,tags",
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="inner-page mobile-app-page">
        <div className="mobile-app-content">
          <div className="siteWidth">
            <div className="text-center">
              <h1>Return and Refund</h1>
              <h4>TECHSELL INDIA</h4>
              <h3>WWW.TECHSELLINDIA.COM</h3>
              <p>Return and Refund Policy</p>
              <p>Effective date: 21, Sep , 2022</p>
              <br />
              <p>
                TECHSELL INDIA (“us”, “we”, or “our”) operates the
                WWW.TECHSELLINDIA.IN website (the “Service”).
              </p>
            </div>
            <p>
              UNFORTUNATELY, DUE TO THE IMMEDIATE ACCESS TO DIGITAL PRODUCTS, ALL SALES ARE FINAL AND NONREFUNDABLE.
            </p>
            <p>
              However, we realise that exceptional circumstance can take place with regard to the character of the product we supply.
            </p>
            <h3>Therefore, we DO honor requests for the refund for the following reasons:</h3>
            <p>
              Specific terms stated on a specific digital product: Some digital products may explicitly state a full or partial refund is based on a satisfaction guarantee.
            </p>
            <p>Non-delivery of the product: due to some mailing issues of your e-mail provider or your own mail server you might not receive a delivery e-mail from us. In this case, we recommend contacting us for assistance. Claims for non-delivery must be submitted within 7 days from the order placing date. Otherwise, the product will be considered received and downloaded;</p>
            <p>If you have any questions about whether a product is right for you, feel free to email us at <b><a href="mailto:info@techsellindia.com">info@techsellindia.com</a></b> before making your purchase.</p>
            <p>
              Since www.techsellindia.com offers non-tangible irrevocable goods we DO NOT issue refunds once the order is accomplished and the product download link is sent. As a customer, you are responsible for understanding this upon purchasing any service at our site.
            </p>
          </div>
        </div>
      </div>
    </DocumentMeta>
  );
}
