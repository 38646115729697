import { Link } from "react-router-dom";
export default function Logo() {
  return (
    <div className="logo">
      <Link to="/">
        <img
          src={process.env.PUBLIC_URL + "/images/logo-1.png"}
          alt=""
          className="max-w-full"
        />
      </Link>
    </div>
  );
}
